
.player {
    border: 1px solid var(--mainColor);
    overflow-y: hidden;
    margin-bottom: 15px;
    transition: all 1s ease-in-out;
}

.players {
    overflow-y: auto;
}

.player_border {
    border-radius: 6px;
}

.player h2 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: var(--white);
    margin-bottom: 0;
    text-align: start
}

.player h6 {
    margin: 0;
    color: #fff;
}

.title_bg {
    background-color: var(--mainColor);
    padding: 3px 0;
    padding-left: 10px;
    padding-right: 10px;
}

.title_bg img {
    width: 20px;
    height: 20px;
}

.players::-webkit-scrollbar {
    width: 12px;
  }
  
  .players::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .players::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
    border: 3px solid var(--white);
  }

  .ml-20 {
    margin-left: 20px;
  }

  .max_height_32 {
    max-height: 32px !important;
  }

  .margin_left_auto {
      margin: 0 auto
  }