.products_title_container {
    background: linear-gradient(90.56deg, var(--mainLineColor) 0%, var(--mainColor) 50.44%, var(--mainColorBackground) 100%);
    height: var(--height_100);
}

.title_container {
    gap: var(--gap_lg);
}

.title_container span {
    font-size: 25px;
    font-weight: var(--fontWeight_700);
    color: var(--white);
}

@media screen and (max-width:360px) {
    .title_container span {
        font-size: 20px;
    }
}