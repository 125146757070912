.product_container {
    gap: 15px;
}
.product_data_container {
    gap: 10px;
    /* max-width: var(--maxwidth_200); */
    max-width: 250px;
}

.data_container {
    max-height: 250px;
    width: 100%;
    /* justify-content: space-between; // space-evenly; */
    justify-content: space-between;
    gap: 10px;
}

.description {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

@media screen and (max-width: 580px) {
    .product_data_container {
        max-width: 220px;
    }
}

@media screen and (max-width: 500px) {
    .product_data_container {
        max-width: 200px;
    }
}
 
@media screen and (max-width: 420px) {
    .product_data_container {
        max-width: 180px;
    }
}

@media screen and (max-width: 390px) {
    .product_data_container {
        max-width: 160px;
    }
}
 
@media screen and (max-width: 360px) {
    .product_data_container {
        max-width: 140px;
    }
}
@media screen and (max-width: 325px) {
    .product_data_container {
        max-width: 120px;
    }
}
 