.arrows {
  color: var(--white);
  font-size: 30px;
  max-height: calc(100% - 20px);
  max-width: calc(100% - 20px);
}
.arrow {
  height: 30px;
  width: 30px;
}

.arrow:hover {
  cursor: pointer;
}