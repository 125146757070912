.max_title {
    font-size: var(--lgFontSize);
    font-weight: var(--fontWeight_600);
    text-align: center;
}
.mid_title {
    font-size: var(--smFontSize);
    font-weight: var(--fontWeight_400);
}
.min_title {
    font-size: 14px;
    font-weight: var(--fontWeight_400);
}

.error_text {
    color: var(--errorTextColor) !important;
}