.title_container {
    gap: var(--gap_md);
}

.subtitle {
    color: var(--mainColor);
    font-weight: var(--fontWeight_500);
    font-size: var(--lgFontSize);
}

.title {
    max-width: var(--maxwidth_580);
    color: var(--black);
    font-size: var(--xlFontSize);
    font-weight: var(--fontWeight_700);
}

.information{
    font-size: var(--lgFontSize);
    color: var(--inputTextColor);
    font-weight: var(--fontWeight_400);
}