.apply_button_container {
    bottom: 5px;
    background: #F6F9FB;
}

.apply_button_container button {
    color: var(--mainColor);
    padding: 20px;
    cursor: auto;
}

.disabled_button:hover {
    cursor: pointer !important;
}