.nav_bar_container {
    height: var(--height_100);
    border-bottom: 1px solid var(--mainColor);
    padding: 0 30px 0 30px;
    box-shadow: 0px 4px 4px 0px rgb(9 188 193 / 40%);
}

.nav_bar_icon {
    width: var(--xlWidth);
    height: 80px;
}

.parametrs_container {
    gap: var(--gap_3xl);
    left: calc(50% - 175px);
}

.parametrs_container a {
    color: var(--mainColor);
    font-style: normal;
    font-weight: var(--fontWeight_600);
    font-size: var(--smFontSize);
    line-height: 29px;
}
.action_menues {
    min-width: 100px;
    max-width: 120px;
}

.transparentBackground {
    opacity: 0;
    z-index: 2;
}

@media screen and (max-width: 950px) {
    .parametrs_container  { 
       display: none !important;
    }
    .action_menues {
        justify-content: end !important;
        gap: 20px;
    }
  }
