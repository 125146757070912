@font-face {
  font-family: 'MontserratVariables';
  src: local('MontserratVariables'), url('../public/assets/fonts/montserrat/veriable/Montserrat-VariableFont_wght.ttf') format('truetype')
}

body {
  margin: 0;
  font-family: MontserratVariables !important; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: MontserratVariables !important; 
}

code {
  font-family: MontserratVariables;
}
.root {
  position: relative;
  min-height: 100vh;
  padding: 0 0 300px 0;
}

#video::-webkit-media-controls {
  display: none !important;
}

.calibre {
  background-color: aliceblue;
}

/* Could Use thise as well for Individual Controls */
*::-webkit-media-controls-play-button,
*::-webkit-media-controls-volume-slider,
*::-webkit-media-controls-mute-button,
*::-webkit-media-controls-timeline,
*::-webkit-media-controls-current-time-display,
*::-webkit-media-controls-panel-container {
  display: none !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}