.slide {
  display: flex;
  gap: 10px;
  position: relative;
  width: 100%;
}

.slide:hover {
  cursor: pointer;
}

.fade_in_animation {
  animation: fade_in_animation 1s 1 linear both
}

@keyframes fade_in_animation {
  0% {
    opacity: 0;
    -webkit-transform:translateX(10%);
    transform: translateX(10%);
    -moz-transform:translateX(10%) ;
  }
}

@media screen and (max-width: 675px) {
  .slide {
    position: absolute;
    bottom: 30px;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 80px);
    left: calc((100% - (100% - 80px))/2);
    margin: auto;
    text-align: center;
  }
}