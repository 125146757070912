.modal_header_container .modal-header {
    padding: 15px 25px;
    height: 50px;
}

.cursor_pointer {
    cursor: pointer;
}

.add_new {
    color: var(--mainColor);
    font-weight: 600;
}

.save{
    margin-top: 10px;
    background-color: var(--mainColor);
    color: var(--white);
    padding: 5px 15px;
    border-radius: 3px;
    opacity: 1;
    font-weight: 600;
}

.cancel{
    color: var(--mainColor);
    padding: 5px 15px;
    margin-top: 10px;
    font-weight: 600;
}

.save:disabled {
    opacity: 0.6;
}

.add_to_playlist {
    width: 98%;
    margin-bottom: 10px;
}

.playlist_name_input::placeholder {
    color: #939695;
    opacity: 1;
}

.playlist_name_input:-ms-input-placeholder {
    color: #939695;
}

.playlist_name_input::-ms-input-placeholder {
    color: #939695;
}

.playlist_name_input:focus {
    border-color: var(--mainColor);
    -webkit-box-shadow: 3px 3px 4px rgba(9, 188, 193, 25%);
    box-shadow: 3px 3px 4px rgba(9, 188, 193, 25%);
}

.gap_10 {
    gap: 10px;
}

.save_button:disabled {
  background: red;
}