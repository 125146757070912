.line_container {
    gap: var(--gap_md);
}

.line_container span {
    font-style: normal;
    font-weight: var(--fontWeight_600);
    font-size: var(--mdFontSize);
    line-height: 24px;
    color: var(--lineTextColor);
}

.font_size_20 {
    font-size: var(--lgFontSize);
}
.line_container_border {
    border-bottom: 0.5px solid #BABABA;
    width: 40%;
}

.gap_25 {
    gap: var(--gap_2xl);
}

