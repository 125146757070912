.gap_20 {
    gap: 20px;
}

.title {
    font-size: 17px;
    font-weight: 550;
}

.edit_img:hover {
    cursor: pointer;
}

.edit_img {
    width: 20px;
}

@media screen and (max-width:400px) {
    .title {
        font-size: 15px;
    }

    .edit_img_container {
        width: 15px;
    }
}