.max_width_20 {
    max-width: 15px;
}
.main_color {
    color: var(--mainColor);
}

.img_cancel:hover {
    cursor: pointer;
}
.gap_5 {
    gap: 20px;
}
.margin_right_20 {
    margin-right: 20px;
}

.gap_20 {
    gap: 20px;
}

.font_weight_500 {
    font-weight: 500;
}

.max_width_100 {
    max-width: 100px;
}

 .data_container {
    max-width: calc(100% - 40px);
 }

 .max_height_50 {
    max-height: 50px;
 }

 .align_items {
    align-items: center;
 }

 @media screen and (max-width: 600px) {
  .flex_wrap {
        flex-wrap: wrap;
    } 
    .gap_20 {
        gap: 10px;
    }
    .align_items {
        align-items: start;
    }
    .padding_top_5 {
        padding-top: 5px;
    }
   }

   @media screen and (max-width: 570px) {
    .flex_wrap {
          flex-wrap: wrap;
      } 
      .gap_20 {
          gap: 10px;
      }
      .margin_right_20 {
        margin-right: 0;
    }
     }
 
 @media screen and (max-width: 485px) {
   .text_align_center {
    text-align: center;
   }
    .text_size {
    font-size: 14px;
   }
  }

   @media screen and (max-width: 440px) {
    .text_size {
    font-size: 12px;
   }
  }

  @media screen and (max-width: 375px) {
    .gap_20 {
     gap: 10px;
    }
   }

   @media screen and (max-width: 370px) {
    .max_width_100 {
        max-width: 85px;
    }
   }

   @media screen and (max-width: 350px) {
    .text_size {
        font-size: 12px;
       }
   }

   @media screen and (max-width: 325px) {
    .text_size {
        font-size: 10px;
       }
       .text_wrap {
        text-wrap: wrap;
    }
   }


   @media screen and (max-width: 315px) {
    .gap_20 {
        gap: 5px;
       }

       .max_width_100 {
        max-width: 80px;
    }
    .flex_wrap {
        flex-wrap: wrap;
    }
  
    .text_align_center {
        text-align: center;
    }
    .max_width_20 {
        max-width: 15px;
    }
    .text_wrap {
        text-wrap: wrap;
    }
   }