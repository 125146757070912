.cursor_pointer {
    cursor: pointer;
}

.add_new {
    color: var(--mainColor);
    font-weight: 600;
}

.add_new input {
    width: 98%;
    margin: auto;
    margin-bottom: 10px;
}
