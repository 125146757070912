.font_waight_600 {
  font-weight: 600;
}

.max_width_1200 {
  max-width: 1200px;
}

.max_width_800 {
  max-width: 800px;
}

.flex_grow {
  flex: 1 0;
} 

.about_page_card_container {
  flex: 1 0;
  padding: 5px;
  min-height: 300px;
  min-width: 250px;
  max-width: 250px;
  border-radius: 5px;
}

.about_page_card {
  padding: 10px;
  border-radius: 5px;
}

.our_story {
  background-color: #11999d;
}

.magic {
  background-color: #38618c;
}

.choose_us {
  background-color: #df984e;
}

.vision {
  background-color: #595f72;
}

.about_page_card_img_container {
  height: 70px;
  width: 70px;
  top: -30px;
  background-color: aliceblue;
  padding: 10px;
  border-radius: 50%;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.about_page_card_img_container img {
  max-width: 50px;
  max-height: 50px;
}

.about_page_card p {
  font-size: 13px;
}

.about_page_card h4 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.text_container {
  border-bottom: 5px solid var(--mainColor);
  max-width: 400px;
}

@media screen and (max-width: 1175px) {
  .about_page_card_container {
    max-width: none;
    min-height: auto;
  }
}
@media screen and (max-width: 500px) {
  .about_company_container {
    padding: 0 !important;
  }
}
