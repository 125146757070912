.width_40_percent{
    width: 40%;
}

.font_size_25 {
    font-size: 25px;
}

.color_black {
    color: #000;
}

.font_weight_600 {
    font-weight: 600;
}

.sign_in_span {
    margin: 0 5px;
    color: var(--mainColor);
    cursor: pointer;
}