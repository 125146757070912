.gap_50 {
    gap: 50px;
} 

@media screen and (max-width:345px) {
    .full_width {
        width: 100%;
    }
}

