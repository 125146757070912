.w_65 {
    width: 65%;
}

.img_large {
    max-height: 50vh;
    min-height: 435px;
    min-width: 100% !important;
}

.img_small {
    height: 172px;
    object-fit: cover;
}

.w-30 {
    width: 28.5%;
    margin-right: 0.5rem;
}

.mb_25 {
    margin-bottom: 25px;
}

.blog_main {
    border: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 8px 6px 13px 0px rgba(118, 118, 118, 25%);
    background-color: var(--white);
    padding: 10px 15px 20px 15px;
}

.blog_main:hover {
    cursor: pointer;
}

.home {
    width: 300px;
}

.blog_main img {
    border-radius: 10px;
}

.gap_10 {
    gap: 20px;
}

.home .title {
    font-size: 25px;
}

.title {
    -webkit-line-clamp: 2 !important;
    line-clamp: 2 !important;
}

.fs_25 {
    font-size: 25px !important;
}

.fs_20 {
    font-size: 20px !important;
}

.fs_15 {
    font-size: 15px;
}

.text_elipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ddescription {
    font-weight: 500;
    margin-top: 3px;
    color: var(--black);
    -webkit-line-clamp: 2 !important;
    line-clamp: 2 !important;
}

.arrow {
    gap: 0;
    padding-left: 0;
    transition: all 0.3s ease-in-out;
}

.arrow:hover {
    gap: 25px;
    transition: all 0.3s ease-in-out;
}

.arrow img {
    width: 30px;
}

.more {
    color: var(--mainColor);
    font-style: italic;
    margin: 0 !important;
}

.w-45 {
    width: 46.5%;
}

@media screen and (max-width: 719px) {
    .img_large {
        min-height: 350px !important;
    }

    .w_65 {
        width: 99%;
        margin: 0% !important;
        margin-right: 0.5rem !important;
    }
}

@media screen and (max-width: 560px) {
    .img_large {
        min-height: 250px !important;
    }
}