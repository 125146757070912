  .player_mini_icon {
    width: 20px;
    height: 20px;
  }
  
  
  .fill_white, #menu img, #center_menu img {
    filter: brightness(0) invert(1);
  }

  .margin_right_10 {
    margin-right: 10px;
  }

  .cursor_pointer {
    cursor: pointer;
  }

  .action_buttons {
    width: 23px;
  }