.slide_list {
  height: 350px;
  -webkit-transition:transform 1s ease-in-out;
  transition: transform 2s ease-in-out;
  width: calc(100% - 100px) !important;
}

@media screen and (max-width: 650px) {
  .slide_list {
    height: 280px;
  }
}

@media screen and (max-width: 675px) {
  .slide_list {
    height: 240px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .slide_list {
    height: 300px;
  }
}
