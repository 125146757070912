.padding_20 {
    padding: 20px;
}

.button_style {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 350px;
    border-radius: var(--lg);
    height: 45px;
}

.facebook {
    background: var(--mainFacebookColor);
}

.provider_images {
    width: var(--width_30);
    height: var(--height_36);
}

.google {
    background: var(--mainGoogleColor);
    color: var(--black);
}

.apple {
    background: var(--black);
}

.facebook span,
.apple span {
    color: var(--mainGoogleColor);
}

.google span {
    color: var(--black)
}

.facebook:disabled,
.google:disabled,
.apple:disabled {
    background: var(--passiveButtonColor);
}

.facebook span,
.google span,
.apple span {
    font-size: 14px;
    line-height: 19px;
}

.color_white {
    color: var(--mainGoogleColor) !important;
}

@media screen and (max-width: 576px) {
    .provider_images {
        width: 20px;
        height: 20px;
    }
}