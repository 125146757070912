.contacts_card_email {
    background-color: rgba(9, 188, 193, 0.26);
    box-shadow: 0px 4px 3px 3px rgb(9 188 193 / 50%);
}

.contacts_card_phone {
    background-color: #EFF5F7;
    box-shadow: 0px 4px 4px 4px #E5EBED;
    ;
}

.contacts_data_container {
    gap: var(--gap_3xl);
    padding: 0 20px;
}