.width_40_percent {
    width: 40%;
}
.position_relative {
    position: relative;
}
.margin_bottom_15 {
    margin-bottom: 15px;
}

.search {
    background-color: var(--mainColor);
    border-radius: 0px 5px 5px 0px;
    padding-left: 5px;
    height: 40px;
}

.search_content img {
    width: 18px;
    height: 18px;
}

.search_modal_content {
    background: #f6f9fb;
    border-top: 0;
}

.clear_img {
    position: absolute;
    top: 10px;
    right: 5px;
}

.search_button {
    background-color: var(--mainColor);
    color: var(--white);
    padding: 5px 15px;
    padding-left: 5px;
    opacity: 1;
    border-radius: 0px 5px 5px 0px;
    font-weight: 600;
}
.form_control {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    background-color: var(--bs-body-bg);
    border: 1px solid #dee2e6;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 0.375rem;
    border-radius: var(--bs-border-radius);
    color: #212529;
    color: var(--bs-body-color);
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.padding_right_25 {
    padding-right: 25px;
}

.search_img {
    position: absolute;
    top: 10px;
    left: 5px;
}

.search_input_container {
    border-radius: 5px 0px 0px 5px;
    padding-left: 25px;
    height: 40px;
}

.search_input_container:focus {
    border-color: #939695;
    border-right: 0;
    outline: 0;
    box-shadow: none;
}

.search_input_container::placeholder {
    color: #939695;
    opacity: 1;
}

.search_input_container:-ms-input-placeholder {
    color: #939695;
}

.search_input_container::-ms-input-placeholder {
    color: #939695;
}

.search_modal {
    position: absolute;
    z-index: 100;
    max-height: 350px;
    overflow-y: auto;
}

.search_modal::-webkit-scrollbar {
    width: 12px;
}

.search_modal::-webkit-scrollbar-track {
    background: transparent;
}

.search_modal::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
    border: 3px solid var(--white);
}

.padding_10_0 {
    padding: 10 0;
}

.search_modal {
    padding: 0;
    background-color: #f6f9fb;
    border-radius: 0 0 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 0;
    border-bottom: 0;
}

.search_modal h6 {
    color: var(--black);
    cursor: pointer;
    width: 100%;
    padding: 10px;
}

.selected_search_modal {
    background-color: #ededed;
}

@media screen and (max-width: 991px) {
    .search_container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .width_40_percent {
        width: 100%;
    }
}

@media screen and (max-width: 887px) {
    .width_40_percent {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .search_container {
        padding-left: 0;
        padding-right: 0;
    }
}
