.gap_5 {
    gap: 5px;
}

.width_360 {
   width: 360px !important;
}

.img_container {
    border-radius: 12px;
    max-width: 360px;
    height: 200px;
}
.img_container img {
    border-radius: 12px;
}
.img_container:hover {
    cursor: pointer;
}
.play_all_text {
    color: var(--white);
    font-weight: 500;
}
.cover_container {
    max-height: 200px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 12px;
    transition: opacity 0.3s;
}  

 .fill_white {
    filter: brightness(0) invert(1);
  }

  @media screen and (max-width:1050px) {
    .width_360 {
        max-width: 300px;
        width: 100%;
    }
    .play_all {
        max-height: 170px;
        height: 100%;
        max-width: 300px;
        width: 100%;
    }
}

@media screen and (max-width:1015px) {
    .play_all {
        max-width: 280px;
    }
}

@media screen and (max-width:860px) {
    .play_all {
        max-height: 200px;
        height: 100%;
        max-width: 360px;
        width: 100%;
    }
}

  @media screen and (max-width:840px) {
    .img_container {
        height: 180px;
    }
}


@media screen and (max-width:520px) {
    .img_container {
        height: 150px;
        max-width: 270px;
    }

    .play_all {
        max-height: 150px;
        height: 100%;
        max-width: 270px;
        width: 100%;
    }
}

@media screen and (max-width:380px) {
    .img_container {
        height: 120px;
        max-width: 200px;
    }

    .play_all {
        max-height: 120px;
        height: 100%;
        max-width: 200px;
        width: 100%;
    }
}

@media screen and (max-width:300px) {
    .img_container {
        height: 100px;
        max-width: 180px;
    }

    .play_all {
        max-height: 100px;
        max-width: 180px;
    }
}

@media screen and (max-width:295px) {
    .img_container {
        height: 80px;
        max-width: 160px;
    }
    .play_all {
        max-height: 90px;
        max-width: 160px;
    }
}
