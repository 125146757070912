.max_height_64 {
    max-height: 64px;
}

.title_container {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.item_img:hover {
    cursor: auto;
}

.title_text {
    font-weight: var(--fontWeight_500);
    font-size: 16px;
}

.addPlay_list_container {
    width: 230px;
    top: 30px;
    transition: all 500ms;
    transition-timing-function: ease-in-out;
    height: 0;
    background: var(--white);
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
    border-radius: 5px;
    z-index: 40000;
}

.height_75 {
    height: 75px;
}

.height_150 {
    height: 150px;
}

.padding_10 {
    padding: 10px;
}

.gap_10 {
    gap: 10px;
}

.add_playList_img_container {
    width: 15px;
    height: 15px;
}

.add_playList_text_container {
    font-size: 15px;
}

.text_container:disabled {
    color: var(--black);
}

.text_container button:hover {
    color: var(--mainColor);
}

.text_container:disabled:hover {
    color: var(--black);
    cursor: auto;
}

.text_container:hover {
    color: var(--mainColor);
}

.text_container:disabled span:hover {
    color: var(--black);
    cursor: auto;
}

.menue_img {
    max-width: 20px;
    height: 13px;
}

.menue_img_container {
    width: 30px;
    height: 30px;
}
