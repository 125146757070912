:root {
    /* Colors */
    --mainColor: #09BCC1;
    --mainColorBackground: #85E0E2;
    --black: #030728;
    --errorTextColor: #D62626;
    --white: #FFFFFF;
    --mainFacebookColor: #1877F2;
    --mainGoogleColor: #F7F7F7;
    --lineTextColor: #6F6F6F;
    --inputLineColor: #8D8D8D;
    --mainLineColor: #11999D;
    --greyTextColor: #969A9B;
    --inputTextColor: #6B6B6B;
    --passiveButtonColor: #D9D9D9;
    --mainItemColor: #F6F9FB;

    /*radus*/
    --sm: 5px;
    --md: 8px;
    --lg: 10px;
    --xl: 12px;
    --xxl: 20px;
    --radus_3xl: 30px;

    /* Font size */
    --xsFontSize: 10px;
    --smFontSize: 16px;
    --mdFontSize: 18px;
    --lgFontSize: 20px;
    --xlFontSize: 30px;

    /* Font weight */
    --fontWeight_300: 300;
    --fontWeight_400: 400;
    --fontWeight_500: 500;
    --fontWeight_600: 600;
    --fontWeight_700: 700;

    /*width*/
    --width_30: 30px;
    --width_45: 45px;
    --width_50: 50px;
    --width_60: 60px;
    --xlWidth: 80px;
    --width_100: 100px;

    --maxwidth_150: 150px;
    --maxwidth_200: 200px;
    --maxwidth_250: 250px;
    --maxwidth_280: 280px;
    --maxwidth_300: 300px;
    --maxwidth_315: 315px;
    --maxwidth_350: 350px;
    --maxwidth_500: 500px;
    --maxwidth_520: 520px;
    --maxwidth_580: 580px;
    --maxwidth_1000: 1000px;
    --maxwidth_1100: 1100px;

    --height_20: 20px;
    --height_36: 36px;
    --height_45: 45px;
    --height_50: 50px;
    --height_80: 80px;
    --height_100: 100px;
    --height_180: 180px;
    --height_300: 300px;
    --height_550: 550px;


    --maxHeight_50: 50px;
    --maxHeight_240: 240px;
    --maxHeight_290: 290px;
    --maxHeight_480: 480px;
    --maxHeight_50: 550px;

    /* Gap */

    --gap_xs: 5px;
    --gap_sm: 8px;
    --gap_md: 10px;
    --gap_lg: 15px;
    --gap_xl: 20px;
    --gap_2xl: 25px;
    --gap_3xl: 30px;
    --gap_4xl: 40px;
    --gap_5xl: 50px;
}

/* @import url('../../styles/Variables.css'); */