.search_content {
    gap: 25px;
    padding: 30px 0;
}

.item_content {
    transition: all 1s;
}

.item_content:hover {
    transform: scale(1.05);
    transition: all 1s;
    cursor: pointer;
}

.bg .absolute {
    right: 10px;
    bottom: 10px;
}

.time {
    margin-bottom: 5px;
    margin-right: 5px;
    font-weight: 600;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 12px !important;
    background-color: rgba(0, 0, 0, 0.8);
}

.time:hover {
    font-size: 12px;
}

.link {
    gap: 0 20px;
}

.absolute {
    right: 5px;
}

.item_content > video {
    height: 0px;
    object-fit: cover;
    border-radius: 12px;
    width: 98.5%;
}

.item_content p {
    width: fit-content;
}

.item_content img {
    border-radius: 10px;
}

.about  {
    color: var(--black);
}

.about > .views {
    gap: 25px;
    background-color: transparent;
}

.video_title {
    font-weight: 600;
}

.about h6 {
    font-size: 14px;
    margin-bottom: 3px;
}

.about > h5 {
    font-size: 1.25rem !important;
    margin-bottom: 5px;
}