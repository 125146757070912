.home_page_container {
    gap: var(--gap_4xl);
}
.gap_20 {
    gap: 20px;
}

.data_container {
    background: var(--mainItemColor);
    gap: var(--gap_3xl);
    padding: 10px 20px 30px 20px;
}

.more_books_container {
    min-width: 175px;
    max-width: var(--maxwidth_200);
    padding: 13px;
    border-radius: var(--md);
    font-weight: var(--fontWeight_400);
    background: var(--mainColor);
    color: var(--mainGoogleColor);
    gap: 10px;
    height: 50px;
}