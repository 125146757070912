.spacing_container {
    padding: 10px;
}
.spacing_item_container {
    max-width: 215px;
    gap: 10px;
}
.spacing_item:hover {
    cursor: pointer;
}
.spacing_item {
    font-size: 30px;
    font-weight: 600;
}
.spacing_item_text {
      font-size: 15px;
      width: 140px;
}
.spacing_item_text_container { 
    height: 30px;
}