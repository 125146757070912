.max_width_330 {
  max-width: 330px;
}

.code_verification_container {
  gap: var(--gap_lg);
}

.resend_button {
  color: var(--mainColor);
}

.resend_button:disabled {
  color: grey;
}

.code_verification {
  gap: var(--gap_lg);
}

.verification_code_container {
  gap: var(--gap_xl);
}

.input_code_container {
  gap: var(--gap_md);
}

.input_verification_code {
  width: var(--width_45);
  height: var(--height_45);
}

.input_verification_code input {
  border: 1px solid var(--black);
  font-size: var(--xlFontSize);
  color: var(--mainLineColor);
  border-radius: var(--md);
  background-color: var(--white);
}

.input_error_code {
  width: var(--width_45);
  height: var(--height_45);
}

.input_error_code input {
  border: 1px solid var(--black);
  color: var(--errorTextColor);
  font-size: var(--xlFontSize);
  border-radius: var(--md);
  background-color: var(--white);
}

.input_verification_code input[type='number']::-webkit-outer-spin-button,
.input_error_code input[type='number']::-webkit-outer-spin-button,
.input_verification_code input[type='number']::-webkit-inner-spin-button,
.input_error_code input[type='number']::-webkit-inner-spin-button,
.input_verification_code input[type='number'] .input_error_code input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

.error_message_container {
  gap: 2px;
}

.error_message_container span {
  color: var(--errorTextColor);
  font-style: normal;
  font-weight: var(--fontWeight_400);
  font-size: var(--xsFontSize);
  line-height: 18px;
}

.error_message_container span :hover {
  border: 1px solid var(--errorTextColor);
}

.resend_container {
  gap: var(--gap_2xl);
  max-width: 330px;
}

.resend_button {
  background: none;
  color: var(--mainColor);
}

.resend_button:disabled {
  color: #d9d9d9;
}

.button_color {
  background: var(--mainColor);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 45px;
}

.button_color:disabled {
  background: var(--passiveButtonColor);
}

.button_color span {
  color: var(--white);
  font-style: normal;
  font-weight: var(--fontWeight_600);
  font-size: var(--smFontSize);
  line-height: 24px;
}

@media screen and (max-width: 576px) {
  .input_verification_code input {
    font-size: 20px;
  }
}