


.age_group_container {
    top: 10px;
    left: 10px;
}
.age_group_item_container {
    background: var(--white);
    width: 50px;
    height: 20px;
    border-radius: 5px;
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
}
.age_group_item {
    font-size: 13px;
    color: var(--mainColor);
}