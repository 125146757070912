.selected_rating_div {
    height: 35px;
    width: 35px;
}

.selected_rating_div_active {
    border-bottom: 1px solid var(--mainColor);
}
.active_star {
    cursor: pointer;
    bottom: 5px;
}

.pasive_star:hover {
    cursor: pointer;
}
