.next_button {
    background:  var(--mainColor); 
    max-width: 350px;
    height: 45px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.max_width_350 { 
    max-width: 350px;
}

.next_button:disabled {
    background: var(--passiveButtonColor);
    width: 100%;
    justify-content: center;
}

.next_button span {
    font-size: var(--mdFontSize);
    color: var(--mainGoogleColor);
    font-weight: var(--fontWeight_600);
}

.auth_container {
    gap: var(--gap_2xl);
}

.auth_container span {
    font-style: normal;
    font-weight: var(--fontWeight_600);
    font-size: 14px !important;
}

.lets_start {
    font-size: var(--lgFontSize);
}
.font_size_10 {
    font-size: 10px !important;
}

.auth_page {
    gap: var(--gap_2xl);
}

.modal_phone_number_container {
    gap: var(--gap_2xl);
}

.phone_input {
     height: 45px
}

.height_45 {
    height: 45px;
}

.drop_down {
    max-width: 350px !important;
    margin: 10px 2px !important;
}
.change_country {
   border-right: 1px solid #212529 !important;
}

.background_red {
    background: var(--black) !important;
}

.style_border_right {
    border-right: 1px solid #212529 !important;
}

@media screen and (max-width: 300px) {
    .drop_down {
        margin: 10px -4px !important;
    }
}