.list_item {
    padding: 0 10px;
}
.main_div {
    height: 150px;
    background-position: unset;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.icon {
    width: 20px;
    height: 12px;
}

.see_all {
    margin: 0;
    font-size: 12px;
    padding-right: 3px;
}

.views {
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 10px 10px;
}

.play_list_item {
    color: var(--white);
}

.playlist_title {
    color: var(--black);
    font-weight: 600;
    margin-top: 5px;
    font-size: 20px;
}

.color_black {
    color: var(--black);
}
.font_size_14 {
    font-size: 14px;
}

.play_cartoon {
    background-color: rgba(0, 0, 0, 0.7);
}

.play_cartoon img {
    width: 30px;
    height: 30px;
    filter: brightness(0) invert(1);
    margin-right: 5px;
}

.play_cartoon p {
    font-size: 16px;
    font-weight: 600;
}

.no_video {
    background-size: 100% 100%;
}

.no_video_title {
    font-size: 20px !important;
}
.min {
    min-width: 300px;
}

@media screen and (max-width:350px) {
    .min {
        min-width: 280px;
    }
}

@media screen and (max-width:330px) {
    .min {
        min-width: 260px;
    }
}