.form-check-input:checked {
    border-color: var(--mainColor) !important;
    background-color: var(--mainColor) !important;
}

.form-check-input {
    box-shadow: none !important;
}

.form-check label {
    color: #4C4E52;
}

.form-check {
    width: 100%;
}

.boxShedow_none {
    box-shadow: none;
}

.form-check-input[type=checkbox]:hover {
    cursor: pointer;
}
.form-check-label {
    cursor: pointer;
}

/* phone input */
.react-tel-input .flag-dropdown.open .selected-flag {
    background: none !important;
}
.react-tel-input .selected-flag:hover {
    background: none !important;
}
.react-tel-input .selected-flag {
    background: none !important;
    /* border-right: 1px solid #212529; */
    width: 45px;
    color: var(--mainColor);
} 

/* modals */
.modal.show .modal-dialog {
    max-width: 500px;
    width: 100%;
}
.modal.show .modal-dialog .modal-content {
    border: 1px solid var(--black);
    background-color: var(--white);
}

.codeVerification {
    display: flex;
    width: 100%;
    max-width: 350px;
    flex-wrap: wrap;
    gap: 5px;
}

.codeVerification .react-tel-input .form-control {
    height: var(--height_45);
    font-size: var(--smFontSize);
    width: 100%;
    border-radius: var(--md);
    border-color: var(--inputLineColor);
    box-shadow: rgb(9 188 193 / 10%) 0px 4px 13px 2px;
}

.codeVerification .react-tel-input .flag-dropdown {
    border-color: var(--inputLineColor);
    border-radius: 8px 0 0 8px;
    background: none !important;
    border: none;
}

.modal-header .btn-close {
    font-size: var(--mdFontSize);
    display: none;
}

.modalHeader {
    padding: 15px 25px;
}

.modalHeaderContainer .modal-header {
    padding: 15px;
    height: 50px;
}

.modal-body {
    max-width: 500px;
    width: 100%;
    padding: 25px 15px 50px 15px !important;
}

.modal-backdrop.show {
    z-index: 3000;
}

.modal {
    z-index: 3001 !important;
    padding-right: 0 !important;
}

@media screen and (max-width: 576px) {
    .modal.show .modal-dialog .modal-content {
        width:100% ;
    }
    .modal-dialog {
        padding: 10px;
        display: flex !important;
        justify-content: center !important;
    }

    .modal {
        display: flex !important;
        justify-content: center !important;
        width: 100% !important;
    }

    .modal-body {
        padding-left: 25px !important;
        padding-right: 25px !important;
    } 

    .modal-header {
        padding: 15px !important;
    }

    .modal-body {
        padding-left: 15px !important;
        padding-right: 15px !important;
    } 
}

/* pageination */
.page-item a {
    border: none;
    color: var(--mainColor);
    display: flex;
    align-items: center;
    font-size: var(--smFontSize)
}

.active>.page-link, .page-link.active, .page-link:hover {
    box-shadow: none;
    background: var(--mainColor) !important;
    color: var(--white) !important;
    border-radius: 20px !important;
    width: 30px !important;
    display: flex;
    justify-content: center !important;
    border: none;
    height: 30px !important;
    align-items: center !important;
    box-shadow: none !important;
    border-radius: 18px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

.page-link:focus {
    box-shadow: none !important;
    background-color: transparent;
}
.page-item:not(:first-child) .page-link {
    color:var(--mainColor)
}

.page-item {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-item:first-child .page-link {
    --bs-pagination-focus-color: var(--mainColor);
}
.no-background, .no-background:hover {
    background: none !important;
    color: var(--mainColor) !important;
}

.pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    --bs-pagination-padding-x: none;
    margin: 0;
    gap: 10px !important;
}

.pagination li a span {
    font-size: 25px;
    font-weight: var(--fontWeight_500);
    display: flex;
    height: 30px;
    align-items: center;
    font-weight: var(--fontWeight_700);
}

.pagination li {
    --bs-pagination-bg: none;
}

.pagination {
    gap: var(--gap_md)x;
}

.pagination li a span:hover {
    background: none !important;
}

a {
    text-decoration: none;
}

/* products Slider */
  .slick-track {
    display: flex !important;
    gap: 10px !important;
    justify-content: center;
  }

  .slick-arrow::before {
    display: none !important;
  }
  
  .slick-slide div {
    display: flex !important;
    justify-content: center !important;
  }

  @media screen and (max-width:330px) {
    .slick-initialized .slick-slide {
        width: 180px !important;
    }
}

@media screen and (max-width:325px) {
  .slick-initialized .slick-slide {
      width: 175px !important;
  }
}

@media screen and (max-width:320px) {
  .slick-initialized .slick-slide {
      width: 169px !important;
  }
}
@media screen and (max-width:317px) {
  .slick-initialized .slick-slide {
      width: 165px !important;
  }
}

@media screen and (max-width:315px) {
  .slick-initialized .slick-slide {
      width: 160px !important;
  }
}
@media screen and (max-width:310px) {
  .slick-initialized .slick-slide {
      width: 155px !important;
  }
} 

/* input userPage */
.MuiOutlinedInput-notchedOutline {
    border-color: var(--mainColor) !important;
    color: var(--mainColor) !important;
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5) !important;
    border-radius: 8px !important;
}
.MuiFormControl-root label{
    color: var(--mainColor) !important;
    font-family: var(--bs-body-font-family) !important;
}
.MuiFormControl-root label:focus{
    color: var(--mainColor) !important;
    font-family: var(--bs-body-font-family) !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}
.css-i44wyl {
    width: 100%;
}

/* video player */
.rh5v-Volume_button svg,
.player-icon {
  width: 20px;
  height: 20px;
}

p {
    margin-bottom: 0;
}

/* play cartoon time line */
.progress {
  height: 3px;
}
  
.progress-bar {
  background-color: var(--mainColor);
}

.thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--mainColor);
    position: absolute;
    left: 0;
    top: 0px;
    cursor: pointer;
    visibility: hidden;
  }

  #volume_thumb {
    top: -6px;
    margin: 10px 0;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1.5s, opacity 1.5s linear;
  }
  
   .visible {
    opacity: 1;
    visibility: visible;
  }

  /* upload cartoon */
  .progress-bar {
    background-color: var(--mainColor) !important;
}

/* page for reading */
*::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track  {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }

/* Phone number */
.phoneInputContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.phoneInputContainer .react-tel-input .form-control {
    height: 56px;
    width: 100%;
    box-shadow: none;
    border-color: var(--mainColor);
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
}

.phoneInputContainer .react-tel-input .flag-dropdown {
    border-color: var(--mainColor);
    width: 45px;
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

