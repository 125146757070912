.height_300 {
    height: 350px;
}
.width_360 {
   width: 360px;
}

.padding_20_0 {
    padding: 20px 0;
}

.playlist_item_container {
    max-width: 400px;
    border-radius: 16px;
    background: var(--white);
    -webkit-filter: drop-shadow(13px 4px 6px rgba(118, 118, 118, 0.17));
}

@media screen and (max-width: 1095px) {
    .playlist_item_container {
        max-width: 380px;
    }
    .max_width_360 {
        max-width: 320px;
    }
}

@media screen and (max-width:1050px) {
    .playlist_item_container {
        max-width: 340px;
    }
    .max_width_360 {
        max-width: 300px;
    }
    .width_360 {
        max-width: 300px;
        width: 100%;
    }
}

@media screen and (max-width:1015px) {
    .playlist_item_container {
        max-width: 320px;
    }

    .width_360 {
        max-width: 280px;
        width: 100%;
    }
}

@media screen and (max-width: 860px) {
    .playlist_item_container {
        max-width: 100%;
        height: auto !important;
        margin: 0 20px;
    }

    .width_360 {
        max-width: 100%;
     }
     .data_container {
        gap: 20px;
        padding: 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
     }
     .height_300 {
        height: auto;
     }
}

@media screen and (max-width: 520px) {
    .playlist_item_container {
        max-width: 100%;
        height: auto !important;
        margin: 0 10px;
    }
}
