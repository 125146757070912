.cursor_pointer {
    cursor: pointer;
} 

.hide {
    opacity: 0;
    transition: visibility 1.5s, opacity 1.5s linear;
}

.progress {
    height: 3px;
}

.progress-bar {
  background-color: var(--mainColor);
} 

.dur_progress {
  margin-bottom: 1px;
  padding-top: 5px;
}

.progress_line {
    transition: 'left 0s ease';
}

.progress-bar {
  background-color: var(--mainColor) !important;
}