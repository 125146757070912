.gap_50 {
    gap: 50px;
}

.data_container {
    padding: 0 20px;
    max-width: calc(400px + 50px + 800px + 40px);
    padding-bottom: 50px;
}

.single_playlist_container {
    height: calc(100vh - 120px - 150px);
}

@media screen and (max-width:1235px) {
    .data_container {
        gap: 30px;
    }
}

@media screen and (max-width:955px) {
    .data_container {
        gap: 20px;
    }
}

@media screen and (max-width: 860px) {
    .data_container {
        flex-wrap: wrap;
    }
}