.cartoon_image_container {
  width: 320px;
  height: 170px;
  padding: 5px;
}

.border_radius_10 {
  border-radius: 10px;
}

.height_160 {
  height: 160px;
}
.cartoon_image_container img {
  background: var(--white);
}

.z_index_1000 {
  z-index: 1000;
}

.cartoon_image_hover {
  transform: scale(1.02);
  border-radius: 0 !important;
  transition: all 1s;
  cursor: pointer;
}

.image_on_mouse_leave {
  border-radius: 0 !important;
  transition: all 1s;
  cursor: pointer;
}

.animation {
  transition: all 500ms;
  transition-timing-function: ease-in-out;
  animation: slider_side_image_animation 1s ease-out forwards;
}

.cursor_pointer:hover {
  cursor: pointer;
}

@keyframes slider_side_image_animation {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }

  100% {
    opacity: 1;
    transform: translateX(5%);
  }
}

@media screen and (max-width:1021px) {
  .cartoon_image_container {
    width: 300px;
  }
}

@media screen and (max-width:980px) {
  .cartoon_image_container {
    width: 300px;
  }
}

@media screen and (max-width:980px) {
  .cartoon_image_container {
    width: 320px;
  }
}


@media screen and (max-width:735px) {
  .cartoon_image_container {
    width: 300px;
  }
}

@media screen and (max-width:701px) {
  .cartoon_image_container {
    width: 300px;
  }
}

@media screen and (max-width:650px) {
  .cartoon_image_container {
    width: 280px;
  }
}

@media screen and (max-width:610px) {
  .cartoon_image_container {
    width: 360px;
  }
}

@media screen and (max-width:400px) {
  .cartoon_image_container {
    width: 300px;
  }
}

@media screen and (max-width:340px) {
  .cartoon_image_container {
    width: 280px;
  }
}

@media screen and (max-width:310px) {
  .cartoon_image_container {
    width: 260px;
  }
}