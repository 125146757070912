.my_books_container {
    gap: 50px;
}

.my_books_item_container {
    gap: 20px;
    width: calc(4 * 390px + 3 * 20px);
}

.my_books {
    padding: 0 20px;
}

.abs {
    background: rgb(256, 256, 256, 0.4);
    z-index: 1000000;
}

.hide {
    height: 0;
}

.fs_14 {
    font-size: 18px;
}

@media screen and (max-width: 1700px) {
    .my_books_item_container {
        width: calc(3 * 390px + 3 * 20px);
    }
}

@media screen and (max-width: 1300px) {
    .my_books_item_container {
        width: calc(2 * 390px + 2 * 20px);
    }
}

@media screen and (max-width: 860px) {
    .my_books_item_container {
        width: calc(390px);
    }

    .fs_14 {
        font-size: 14px !important;
    }

    .tabbar_small {
        flex-direction: column !important;
        font-size: 14px;
        gap: 0 !important;
    }
}


@media screen and (max-width: 500px) {
    .fs_14 {
        font-size: 14px;
    }

    .tabbar_small {
        flex-direction: column !important;
        font-size: 14px;
        gap: 0 !important;
    }
}

@media screen and (max-width: 430px) {
    .my_books_item_container {
        width: calc(390px);
        justify-content: center !important;
    }

    .tabbar_small {
        flex-direction: column !important;
        font-size: 14px;
        gap: 0 !important;
        width: fit-content !important;
    }
}