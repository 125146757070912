.advanced_container {
  gap: 10px;
  max-width: 150px;
}

.spaceing_img_container {
  padding: 10px;
}

.spaceing_img_container img {
    width: 10px;
}
.spaceing_img_container img:hover {
  cursor: pointer;
}