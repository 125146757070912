.rate_text {
    font-size: var(--lgFontSize);
    color: var(--black);
}

.rating_container:hover {
    cursor: pointer;
  }
@media screen and (max-width:500px) {
    .rate_text {
        min-height: 35px;
    }
}

@media screen and (max-width:425px) {
    .rate_text {
        font-size: 14px !important;
    }
}

@media screen and (max-width:345px) {
    .rate_text {
        min-width: 40px;
    }
}