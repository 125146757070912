.rating_conteiner {
    gap: 20px;
}

.rating_img_container {
    width: 260px;
    height: 50px;
    gap: 10px;
}

.rate {
    max-width: 210px;
    height: 40px;
    border-radius: 8px;
}

.pasive_rate {
    height: 35px;
    width: 35px;
}

.active_rate {
    height: 50px;
    width: 50px;
}

.active_rate img {
    height: 100%;
}

.pasive_rate:hover {
    cursor: pointer;
}

.pasive_rate img:hover {
    cursor: pointer;
    width: 50px;
}

.rate_button {
    background: var(--mainColor);
    color: var(--mainGoogleColor);
    gap: 10px;
    height: 50px;
    padding: 20px;
}
