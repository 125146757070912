.carousel_container {
    max-width: 1000px;
}

.slider_item {
    width: 200px;
    max-width: 200px;
    height: 300px;
    background-color: var(--black);
    border-radius: 8px;
}

.gap_40 {
    gap: 40px;
}

.slider_item img {
    border-radius: 8px;
}

.slider_item:hover img {
    opacity: 0.5;
    background-color: var(--black);
}

.slider_item:hover {
    display: flex !important;
}

.text {
    color: var(--white);
    font-weight: var(--fontWeight_600);
}

.explore_more {
    color: var(--white);
    font-size: var(--mdFontSize);
}

.buttons {
    border-radius: 50% !important;
    width: 10px !important;
    height: 30px !important;
    padding: 5px !important;
}

.buttons img {
    margin: 0 10px;
    filter: invert(40%) sepia(70%) saturate(524%) hue-rotate(133deg) brightness(87%) contrast(100%);
}

@media screen and (max-width: 1100px) {
    .carousel_container {
        width: 100%;
        max-width: calc(100% - 100px);
    }
}