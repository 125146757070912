.input_container {
  background: var(--white);
  border-radius: 5px;
  max-width: 400px;
  height: 45px;
  box-shadow: 3px 3px 4px rgba(133, 224, 226, 0.5);
  margin-bottom: 3px;
}

.cartoon_search_container {
  max-width: 400px;
}

.search_book_modal {
  width: 660px;
  z-index: 10000;
  max-height: 350px;
  background-color: #f6f9fb;
  border-radius: 0 0 10px 10px;
  border: 2px solid rgba(133, 224, 226, 0.5);
  margin-left: 2px;
}

.input_container input {
  border: none;
  outline: none;
  width: 100%;
  height: 35px;
  padding: 10px;
}

.align_items_center {
  align-items: center;
}

.max_width_400 {
  max-width: 400px;
}
.full_width {
  width: 100%;
}
.clear {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.padding_y_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}



.search_book_modal::-webkit-scrollbar {
  width: 12px;
}

.search_book_modal::-webkit-scrollbar-track {
  background: transparent;
}

.search_book_modal::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
  border: 3px solid var(--white);
}


.search_book_modal h6 {
  color: var(--black);
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.search_book_modal_content {
  background: #f6f9fb;
  border-top: 0;
}

.selected_search_book_modal {
  background-color: #ededed;
}

@media screen and (max-width:1360px) {
  .input_container,
  .search_book_modal {
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .input_container {
    max-width: none;
  }
  .search_book_modal {
    max-width: none;
  }
}
@media screen and (max-width: 980px) {
  .cartoon_search_container {
    max-width: none;
    width: 100%;
  }

  .productsSearchContainer {
    max-width: none;
    width: 100%;
  }
}


@media screen and (max-width:500px) {
  .input_container,
  .search_book_modal {
    width: 100%;
  }

}

@media screen and (max-width:400px) {
  .search_book_modal {
    top: 48px !important;

  }
  .cartoon_search_container {
    display: flex;
    justify-content: center;
  }
  .productsSearchContainer {
     display: flex;
    justify-content: center;
  }
}

@media screen and (max-width:310px) {
  .cartoon_search_container {
    justify-content: start;
  }
  .productsSearchContainer {
    justify-content: start;    
   }
}
