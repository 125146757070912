.spinner_container {
    color:var(--mainColor);
    z-index: 1;
}
.background_transparent {
    background-color:rgba(0, 0, 0, .5);
    height: calc(100vh - 100px);
}

.height_100 {
    height: 100vh;
}
.height_50 {
    height: 50vh;
}