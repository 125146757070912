.min_height_420 {
    min-height: 420px;
}

.image_container {
    max-width: 200px;
    min-width: 200px;
}

.image_container img {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
}

.gap_50 {
    gap: 50px 0;
}

.bestsellers_card {
    width: 22%;
}

.books_contnt {
    max-width: calc(3 * 25% + 2 * 20px);
    gap: 30px 2.5%;
}

.padding_10 {
    padding: 10px !important;
}

.box_shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}

.main_width {
    width: 18%;
    padding: 0 10px;
}

.box_shadow {
    -webkit-box-shadow: 8px 6px 13px 0px rgba(118, 118, 118, 25%);
}

@media screen and (max-width: 1950px) {
    .main_width {
        width: 18%;
        padding: 0 10px;
    }

    .books_contnt {
        gap: 30px 2%;
    }
}

@media screen and (max-width: 1850px) {
    .main_width {
        width: 20%;
        padding: 0 10px;
    }

    .books_contnt {
        gap: 30px 6.5%;
    }
}

@media screen and (max-width: 1700px) {
    .main_width {
        width: 21%;
        padding: 0 10px;
    }

    .books_contnt {
        gap: 30px 5%;
    }
}

@media screen and (max-width: 1600px) {
    .main_width {
        width: 22%;
        padding: 0 10px;
    }

    .books_contnt {
        gap: 30px 4%;
    }
}

@media screen and (max-width: 1500px) {
    .main_width {
        width: 22%;
        padding: 0;
    }

    .books_contnt {
        gap: 30px 3.5%;
    }
}

@media screen and (max-width: 1400px) {
    .main_width {
        width: 22%;
        padding: 0;
    }

    .books_contnt {
        gap: 30px 3.5%;
    }
}

@media screen and (max-width: 1320px) {
    .main_width {
        width: 22%;
        padding: 0;
    }

    .books_contnt {
        gap: 30px 3%;
    }
}

@media screen and (max-width: 1270px) {
    .main_width {
        width: 28%;
        padding: 0 10px;
    }

    .books_contnt {
        gap: 30px 7.5%;
    }
}

@media screen and (max-width: 1180px) {
    .main_width {
        width: 28%;
        padding: 0;
    }

    .books_contnt {
        gap: 30px 8%;
    }
}


@media screen and (max-width: 1050px) {
    .main_width {
        width: 30%;
        padding: 0 10px;
    }

    .books_contnt {
        gap: 30px 4%;
    }
}

@media screen and (max-width: 950px) {
    .main_width {
        width: 45%;
        padding: 0px;
    }

    .books_contnt {
        gap: 30px 10%;
        width: 500px !important;
    }
}

@media screen and (max-width: 820px) {
    .qwert {
        max-width: 90%;
    }
}

@media screen and (max-width: 800px) {
    .main_width {
        width: 45%;
        padding: 0 10px;
    }

    .books_contnt {
        gap: 30px 10%;
    }
}

@media screen and (max-width: 700px) {
    .main_width {
        width: 46%;
        padding: 0px;
    }

    .books_contnt {
        gap: 30px 8%;
        justify-content: start !important;
    }
}


@media screen and (max-width: 640px) {
    .main_width {
        width: 45%;
        padding: 0px;
        min-width: 45%;
    }

    .books_contnt {
        gap: 30px 3%;
        justify-content: start !important;
    }
}

@media screen and (max-width: 600px) {
    .main_width {
        width: 65%;
        padding: 0 20px;
    }

    .books_contnt {
        gap: 30px 0%;
        justify-content: center !important;
    }
}

@media screen and (max-width: 350px) {
    .main_width {
        width: 75%;
        padding: 0;
    }

    .books_contnt {
        gap: 30px 0%;
        justify-content: center !important;
        min-width: 300px;
    }
}