.image {
    width: 35% !important;
    height: 75px;
    border-radius: 6px;
    margin-right: 10px;
    margin-left: 20px;
    max-width: 180px;
  }
  
  .image img,
  .smiliar_image img {
    width: 100% !important;
  }
  
  .image p,
  .smiliar_image p {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .position_relative {
    position: relative;
  }
  
  .duration {
    position: absolute;
    right: 4px;
    bottom: 4px;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 12px !important;
  }
  
  .font_bold {
    font-weight: 600;
  }
  
  .description {
    height: 75px;
    padding: 5px 0;
    max-width: 55%;
  }
  
  .description p {
    color: var(--black);
  }
  
  .line_height_24 {
    line-height: 24px;
  }
  
  .cartoon_title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--black);
    font-size: 16px;
  }
  
  .main_h {
    height: 75px;
    margin-bottom: 15px;
  }
  
  .cursor_pointer {
    cursor: pointer;
  }
  
  .img {
    height: 75px;
    object-fit: fill;
  }
  
  .selected {
    background-color: rgba(17, 153, 157, 0.1) !important;
    padding: 0;
  }
  
  .selected .image {
    margin-left: 0px !important;
  }
  
  .next {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
  
  .item:hover {
    background-color: rgba(17, 153, 157, 0.1);
  }
  
  .smiliar_image {
    width: 35% !important;
    min-width: 115px !important;
    height: 75px;
    border-radius: 6px;
    margin-right: 10px;
    max-width: 180px;
  }
  
  .description p {
    font-size: 16px;
  }
  
  .relative {
    position: relative;
  }
  
  .trash {
    position: absolute;
    right: 5px;
    top: 10px;
    width: 20px;
  }
  
  .top {
    top: 35px;
    right: 15px;
  }
  
  .top_last {
    top: -60px;
    right: 15px;
  }
  
  .center {
    top: 2px;
    right: 20px;
  }
  
  .playlist_modal {
    padding: 5px 10px;
    max-width: 83%;
    position: absolute;
    background-color: var(--white);
    color: var(--black);
    gap: 5px;
    height: 70px !important;
    border-radius: 8px;
    z-index: 1000000;
    -webkit-box-shadow: 6px 6px 13px 0px rgba(118, 118, 118, 25%);
    box-shadow: 6px 6px 13px 0px rgba(118, 118, 118, 25%);
    right: 20px;
  }
  
  .playlist_modal_item {
    gap: 10px;
  }
  
  .playlist_modal_item img {
    width: 15px;
    height: 15px;
  }
  
  .mb_5 {
    margin-bottom: 5px;
  }
  
  .playlist_modal_item span {
    text-overflow: ellipsis;
    height: 20px;
    -webkit-box-orient: vertical;
    color: var(--black);
    font-size: 12px !important;
    font-weight: 500;
  }
  
  .margin_top_10 {
    margin-top: 10px;
  }
  
  .padding_right_20 {
    padding-right: 20px;
  }
  
  .font_size_13 {
    font-size: 13px !important;
  }
  
  .cartoon_category {
    text-overflow: ellipsis;
    text-wrap: nowrap;
    color: var(--black);
  }
  
  .cartoon_title {
      -webkit-line-clamp: 2;
      line-clamp: 2;
  }
  
  .loader > div {
    height: 70px !important;
  }
  
  .loader .spinner-border {
    width: 20px;
    height: 20px;
  }
  
  
  .category_image {
    width: 15px !important;
    height: 15px !important;
    margin-right: 3px !important;
  }
  