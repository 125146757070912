.text_vinni {
    color: var(--mainColor);
    font-weight: 500;
}

.like_count { 
    font-size: 20px !important;
}

.like_icon {
    width: 30px;
    height: 25px;
    margin-right: 5px;
}

.like_icon:hover {
    cursor: pointer;
}

.author {
    font-weight: 600;
    font-size: 18px;
}

.views_count {
    color: var(--black);
    font-weight: 600;
}

.more, more:active {
    background-color: transparent;
    padding: 0;
    font-weight: 500;
    cursor: pointer;
    font-style: italic;
    margin-bottom: 20px;
    color: var(--mainColor);
}

.categoriesss .more {
    margin: 0;
}

.categoriesss {
    padding: 15px 25px;
    background-color: rgba(214, 212, 212, 0.3);
    border-radius: 5px;
    border: 1px solid #d1cfcf;
    margin-bottom: 20px;
}

.categoriesss .more {
    margin: 0;
}

.categorie {
    padding: 5px 10px;
    border: 1px solid var(--mainColor);
    align-items: center;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.categorie p {
    margin: 0;
}

.m_r_10 {
    margin-right: 10px;
}

.category_image {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px;
}

.categories {
    font-style: italic;
}

.about_video, #view_count {
    color: #bababa !important;
}
