.z_index_3300 {
    z-index: 3300;
}
.back_drop_background {
    background: grey;
    opacity: 0.5;
}

.min_height_628 {
    min-height: 628px;
}

.gap_20 {
    gap: 20px;
}

.side_bar_icon {
    width: 50px;
    color: var(--mainColor);
}

.cartoons_item_container {
    max-width: calc(4 * 320px + 3 * 20px + 40px);
}

.gap_50 {
    gap: 50px;
}

.cartoons_container {
    max-width: calc(4 * 320px + 3 * 20px + 40px);
}

.abs {
    background: rgb(256, 256, 256, 0.4);
    z-index: 1000000;
}

.hide {
    height: 0;
}

.searchBookModal {
    z-index: 10000;
    max-height: 350px;
    top: 98px !important
}

@media screen and (max-width:1380px) {
    .cartoons_item_container {
        max-width: calc(3 * 320px + 2 * 20px);
    }

    .cartoons_container {
        max-width: calc(3 * 320px + 2 * 20px);
    }
}

@media screen and (max-width:1040px) {
    .cartoons_item_container {
        max-width: calc(3 * 320px + 2 * 10px);
    }

    .gap_20 {
        gap: 10px;
    }

    .cartoons_container {
        max-width: calc(3 * 320px + 2 * 10px);
    }
}

@media screen and (max-width:1700px) {
    .cartoons_item_container {
        max-width: calc(3 * 320px + 2 * 20px);
    }

    .gap_20 {
        gap: 20px;
    }

    .cartoons_container {
        max-width: calc(3 * 320px + 2 * 20px + 40px);
    }
}

@media screen and (max-width:1360px) {
    .cartoons_item_container {
        max-width: calc(2 * 320px + 20px);
    }

    .gap_20 {
        gap: 20px;
    }

    .cartoons_container {
        max-width: calc(2 * 320px + 20px + 40px);
    }
}

@media screen and (max-width:1021px) {
    .cartoons_item_container {
        max-width: calc(2 * 300px + 20px);
    }

    .gap_20 {
        gap: 20px;
    }

    .cartoons_container {
        max-width: calc(2 * 300px + 20px + 40px);
    }
}

@media screen and (max-width:980px) {
    .cartoons_item_container {
        padding: 0 20px;
        max-width: calc(2 * 300px + 20px + 40px);
    }

    .filter_container {
        gap: 20px;
        flex-wrap: wrap;
    }

    .gap_20 {
        gap: 20px;
    }

    .cartoons_container {
        max-width: calc(2 * 320px + 40px + 20px + 40px);
    }

    .cartoons_item_container {
        max-width: calc(2 * 320px + 20px + 40px);
    }

    .gap_20 {
        gap: 20px;
    }

    .cartoons_container {
        max-width: calc(2 * 320px + 20px + 40px + 40px);
    }

    .cartoons_container {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width:735px) {
    .cartoons_item_container {
        max-width: calc(2 * 300px + 20px + 40px + 40px);
    }

    .gap_20 {
        gap: 20px;
    }

    .cartoons_container {
        max-width: calc(2 * 300px + 20px + 40px);
    }
}

@media screen and (max-width:701px) {
    .cartoons_item_container {
        padding: 0 10px;
        max-width: calc(2 * 300px + 20px + 20px);
    }

    .gap_20 {
        gap: 20px;
    }

    .cartoons_container {
        max-width: calc(2 * 300px + 20px + 20px);
    }
}

@media screen and (max-width:650px) {
    .cartoons_item_container {
        max-width: calc(2 * 280px + 20px + 20px);
    }

    .cartoons_container {
        max-width: calc(2 * 280px + 20px + 20px);
    }
}

@media screen and (max-width:610px) {
    .cartoons_item_container {
        max-width: 360px;
    }

    .cartoons_container {
        max-width: 360px;
    }
}

@media screen and (max-width:400px) {
    .cartoons_item_container {
        max-width: calc(300px + 40px + 20px);
        justify-content: center;
    }

    .cartoons_container {
        max-width: calc(300px + 40px + 20px);
    }

    .infinite_scroll_container {
        justify-content: center;
    }

    .filter_container {
        justify-content: center !important;
        max-width: 300px;
    }
}

@media screen and (max-width:340px) {
    .cartoons_item_container {
        max-width: calc(280px + 40px + 20px)
    }

    .cartoons_container {
        max-width: calc(280px + 40px + 20px)
    }
    .filter_container {
        max-width: 280px;
    }
}

@media screen and (max-width:310px) {
    .cartoons_item_container {
        max-width: calc(310px + 20px + 20px)
    }

    .cartoons_container {
        max-width: calc(310px + 20px + 20px)
    }
    .filter_container {
        max-width: 260px;
    }
}