.cartoon_image_container {
    max-width: 100px;
    max-height: 200px;
}
.gap_10 {
    gap: 10px;
}

.choose_file_container:hover {
    cursor: pointer;
}
.choose_file {
    color: var(--mainColor);
    font-weight: 500;
    font-size: 16px;
}