.advenced_container {
    padding: 0 5px;
    height: 30px;
}
.advenced_container:hover {
    cursor: pointer;
}

.paddin_5 {
    padding: 5px;
}

.advenced_item_container {
    gap: 20px;
    padding: 5px;
    background: #eaeaea;
    border-radius: 8px;
    height: 30px;
}

.advenced_item_text {
    font-size: 14px
}