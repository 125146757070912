.read_books_setings_container {
    padding: 15px 40px 0 ;
}

.prev_button_container {
    max-width: 50px;
    -webkit-user-select: none;
}
.text_settings_img_container {
    width: 50px;
}

.text_settings_img_container:hover {
    cursor: pointer;
}
.read_book_settings {
    z-index: 110;
    max-width: 255px;
    background: var(--white);
    min-height:200px;
    transition: all 500ms;
    transition-timing-function: ease-in-out;
    top: 91px;
    right: 40px;
     border-radius: var(--xl);
     box-shadow: 6px 6px 4px 5px rgba(116, 116, 116, 0.1); 
     padding-bottom: 10px;
     -webkit-user-select: none;
}
.item_settings_container {
    display: flex;
    flex-wrap: wrap;
}
