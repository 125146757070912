.user_cartoons_container {
    max-width: calc(5 * 320px + 6 * 20px);
    padding: 0 20px;
}

.gap_50 {
    gap: 50px;
}

.gap_20 {
    gap: 20px;
}

@media screen and (max-width: 1800px) {
    .user_cartoons_container {
        width: calc(4 * 320px + 4 * 20px + 20px);
    }
}

@media screen and (max-width: 1400px) {
    .user_cartoons_container {
        width: calc(3 * 320px + 3 * 20px + 20px);
    }
}

@media screen and (max-width: 1052px) {
    .user_cartoons_container {
        width: calc(2 * 320px + 2 * 20px + 20px);
    }
}

@media screen and (max-width: 1021px) {
    .user_cartoons_container {
        width: calc(2 * 300px + 2 * 20px + 20px);
    }
}

@media screen and (max-width: 982px) {
    .user_cartoons_container {
        width: calc(2 * 320px + 2 * 20px + 20px);
    }
}

@media screen and (max-width: 735px) {
    .user_cartoons_container {
        width: calc(2 * 300px + 2 * 20px + 20px);
    }
}

@media screen and (max-width: 680px) {
    .user_cartoons_container {
        width: calc(320px);
    }
}

@media screen and (max-width: 610px) {
    .user_cartoons_container {
        width: calc(380px);
    }

}
