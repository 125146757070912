.products_container:hover {
    cursor: pointer;
}

.title {
    font-size: var(--mdFontSize);
    font-weight: var(--fontWeight_500);
    color: #4C4E52;
    border-bottom: 1px solid var(--greyTextColor);
}

.show_product {
    max-width: 30px;
}
