.duration_container {
    right: 10px;
    bottom: 10px;
}
.duration_item_container {
    background: var(--white);
    width: 50px;
    height: 20px;
    border-radius: 5px;
    color: var(--mainColor);
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
}
.duration_item {
    font-size: 13px;
    font-weight: 500;
}