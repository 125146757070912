.category_item_container {
    gap: 15px;
}

.edit_category_container {
  max-width: 222px;
}

@media screen and (max-width:742px) {
    .edit_category_container {
        max-width: 217px;
    }
}
@media screen and (max-width:731px) {
    .edit_category_container {
        max-width: 210px;
    }
}
@media screen and (max-width:715px) {
    .edit_category_container {
        max-width: 200px;
    }
}

@media screen and (max-width:682px) {
    .edit_category_container {
        max-width: 182px;
    }
}

@media screen and (max-width:622px) {
    .edit_category_container {
        max-width: 225px;
    }
}

@media screen and (max-width:600px) {
    .edit_category_container {
        max-width: 220px;
    }
}
@media screen and (max-width:510px) {
    .edit_category_container {
        max-width: 210px;
    }
}
@media screen and (max-width:490px) {
    .edit_category_container {
        max-width: 200px;
    }
}

@media screen and (max-width:470px) {
    .edit_category_container {
        max-width: 180px;
    }
}
@media screen and (max-width:430px) {
    .edit_category_container {
        max-width: 160px;
    }
}
@media screen and (max-width:390px) {
    .edit_category_container {
        max-width: none;
    }
}