.backdrop_container {
  background: grey;
  opacity: 0.5;
  z-index: 3300;
}

.side_bar_icon {
    width: 50px;
    height: 50px;
    color: var(--mainColor);
}

.products_container {
  gap: 50px;
}

.product_container {
  gap: var(--gap_5xl);
  padding-bottom: 50px;
}

.filter_products {
  padding: 0 30px;
}

.filter_data_container {
  gap: 20px;
  max-width: calc(3 * 300px + 2 * 20px);
}

.data_container {
  max-width: calc(4 * 300px + 3 * 20px + 250px);
}

.abs {
  background: rgb(256, 256, 256, 0.4);
  z-index: 1000000;
}

.hide {
  height: 0;
}

@media screen and (max-width: 1315px) {
  .filter_data_container {
    max-width: calc(3 * 300px + 2 * 20px)
  }

  .filter_data_container {
    max-width: calc(3 * 300px + 2 * 20px)
  }
}

@media screen and (max-width: 1200px) {
  .filter_data_container {
    max-width: calc(2 * 300px + 20px)
  }

  .filter_data_container {
    max-width: calc(2 * 300px + 20px)
  }
}

@media screen and (max-width: 1005px) {
  .filter_data_container {
    width: 100%;
    max-width: calc(3 * 300px + 2 * 20px)
  }
}

@media screen and (max-width: 945px) {
  .filter_data_container {
    width: 100%;
    max-width: calc(2 * 300px + 20px)
  }
}


@media screen and (max-width: 780px) {
  .filter_data_container {
    width: 100%;
    max-width: calc(2 * 300px + 20px)
  }
}
@media screen and (max-width: 590px) {
  .filter_data_container {
    width: 100%;
    max-width: calc(300px)
  }
}

@media screen and (max-width: 500px) {
  .filter_data_container {
    width: 100%;
    max-width: 300px
  }
}


@media screen and (max-width: 820px) {
  .searchBookModal {
    top: 92px !important;
  }
}