.padding_20 {
    padding: 0 20px;
}

.cards_container {
    text-overflow: ellipsis;
    color: var(--black);
}

.card_item_rating_text {
    font-size: var(--mdFontSize);
    font-weight: var(--fontWeight_500);
    color: var(--black);
}