.main {
    max-width: 1500px;
    padding: 0 20px;
}

.playlist_cartoon {
    padding-right: 3rem;
    padding-left: 0;
}

.smilar_max_height {
    max-height: 800px;
}

.main p {
    font-size: 16px;
}

@media screen and (max-width: 991px) {
    .playlist_cartoon {
        padding-left: 3rem;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .playlist_cartoon {
        padding-left: 0;
        padding-right: 0;
    }
}