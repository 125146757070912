.audio_container {
    gap: 20px;
}

.item_container {
    background: var(--mainColor) !important;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}
.item_container button:focus-visible {
    outline: none;
}
.background_main_color {
    background: var(--mainColor) !important;
}
.background_gray {
    background: var(--passiveButtonColor) !important;
}
.item_container button:hover {
    cursor: pointer;
}
.item_container button:disabled:hover {
   cursor: auto;
}

.width_15 {
    width: 15px; 
}
.item_container:hover {
    cursor: pointer;
}

@media screen and (max-width: 330px) { 
    .audio_container {
        gap: 10px;
    }
}