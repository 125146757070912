.borde_radius_10 {
    border-radius: 10px;
}

.gap_10 {
    gap: 10px;
}

.img_container {
    max-height: 335px;
}
.height_335 {
    height: 335px !important;
}

.video_container {
    max-height: 335px;
    gap: 10px;
}

.upload_video_container {
    gap: 20px;
}

.upload_video_container:hover {
    cursor: pointer;
}

.choose_file_container:hover {
    cursor: pointer;
}

.background_image {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.default_bg {
    background: var(--black);
    border-radius: 10px;
}

.choose_file {
    color: var(--mainColor);
    font-weight: 500;
    font-size: 16px;
}

.upload_img_container img {
    max-width: 70px;
}
.upload_image {
    max-width: 70px
}
.upload_image:hover {
    cursor: pointer;
}

.error_text {
    color:var(--errorTextColor);
    font-size: 14px;
    font-weight: 500;
}

.img_container_border_radius {
    border-radius: 10px;
}
@media screen and (max-width:1140px) {
  .img_container {
    max-height: 300px;
  }
  .video_container {
    max-height: 300px;
  }
}

@media screen and (max-width:1040px) {
  .img_container {
    max-height: 290px;
  }
    .video_container {
    max-height: 290px;
  }
}
@media screen and (max-width:1030px) {
  .img_container {
    max-height: 270px;
  }
    .video_container {
    max-height: 270px;
  }
}

@media screen and (max-width:980px) {
    .video_container {
        justify-content: center;
        max-height: none;
    }
    .img_container {
        max-height: none;
    }
}