.gap_20 {
    gap: 20px;
}

.gap_5 {
    gap: 5px;
}
.padding_10{
    padding: 10px;
}

.language_container {
    height: 50px;
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
    background: var(--white);
}

.font_size_14 {
    font-size: 14px;

}
.active_language {
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: 600; 
}

.item:hover {
    cursor: pointer;
    color: var(--mainColor);
}

@media screen and (max-width:1095px) {
    .font_size_14 {
       font-size: 12px;

    }
}
@media screen and (max-width:980px) {
    .font_size_14 {
       font-size: 14px;

    }
}

@media screen and (max-width:515px) {
    .language_items_container {
        overflow-x: scroll;

    }
}
