.font_size_14 {
  font-size: 14px;
}

.max_width_100 {
  max-width: 100px !important;
}

.dynamic_logo_container {
  max-width: 120px;
}

.logo_container {
  width: var(--xlWidth);
  height: var(--height_80);
}

.receved_text {
  font-size: var(--smFontSize);
  gap: 5px;
}

.receved_text a {
  color: var(--mainColor);
}

.footer_container {
  height: var(--height_180);
  border-top: 1px solid var(--mainColor);
}

.link_item {
  height: 50px;
  max-width: 150px;
}

.max_width_100 {
  max-width: 100px;
}

.padding_bottom_100 {
  padding-bottom: 100px;
}

.max_width_100 img {
  width: 100%;
}

.display_none {
  display: none !important;
}

.display_flex {
  display: flex;
}

@media screen and (max-width: 820px) {
  .receved_text {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 760px) {
  .logo_container {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .receved_text {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .padding_bottom_100:last-child {
    justify-content: center !important;
  }
}