.category_image {
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-height: 440px;
}
.category_image:hover {
    cursor: pointer;
}

.position_container {
    position: absolute;
}