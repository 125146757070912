.max_height_147 {
    max-height: 147px;
}

.max_width_340 {
    max-width: 340px;
}

.author_title {
    font-size: var(--mdFontSize);
    font-weight: var(--fontWeight_700);
    color: var(--black);
}

.author_img_container {
    max-width: 100px;
    float: left;
    margin: 10px 20px 20px 0;
}

.max_width_100 {
    max-width: 100px;
}

.books_title {
    font-size: var(--mdFontSize);
    font-weight: var(--fontWeight_500);
    color: var(--black);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.author_name {
    font-weight: var(--fontWeight_700);
}

.author_data {
    font-weight: var(--fontWeight_400);
    font-size: 14px;
}

.gap_10 {
    gap: 10px;
}

.gap_15 {
    gap: 15px;
}

.gap_40 {
    gap: 40px;
}

.max_width_510 {
    max-width: 510px;
}

.books_container {
    gap: 15px;
    max-width: 340px;
    margin: 10px 0;
}

.books_item {
    width: 160px;
    max-height: 200px;
    background: var(--white);
    filter: drop-shadow(3px 3px 4px rgba(133, 224, 226, 0.25));
}

.books_item span {
    font-size: 14px;
    font-weight: var(--fontWeight_400);
}

.books_item:hover {
    cursor: pointer;
}

.item_data {
    gap: 10px;
    padding: 10px;
}

.text {
    color: var(--black);
    font-weight: var(--fontWeight_600);
    font-size: var(--mdFontSize);
}

.padding_10 {
    padding: 0 10px;
}

.cursor_pointer {
    cursor: pointer;
}

@media screen and (max-width:910px) {
    .books_container {
        justify-content: center;
        max-width: calc(4 * 160px + 3 * 20px);
    }
}

@media screen and (max-width:705px) {
    .books_container {
        max-width: calc(2 * 160px + 20px);
    }
}

@media screen and (max-width:460px) {
    .max_width_510 {
        max-width: none;
    }
}
