.filter_data_container {
    max-width: calc(4 * 300px + 3 * 20px);
}

.page_container span {
    font-size: var(--mdFontSize);
    font-weight: var(--fontWeight_400);
    color: var(--black);
}
.card_container {
    gap: 20px;
}

.padding_50 {
    padding-top: 50px;
}

.bestsellers_card {
    width: 100%;
}

@media screen and (max-width: 1325px) {
    .bestsellers_card {
      width: 31.9%;
    }
  } 

  @media screen and (max-width: 1310px) {
    .bestsellers_card {
      width: 31.7%;
    }
  } 


@media screen and (max-width: 1200px) {
  .bestsellers_card {
    width: 50%;
  }
} 

@media screen and (max-width: 1005px) {
    .bestsellers_card {
      width: 48.3%;
    }
  } 
  @media screen and (max-width: 980px) {
    .bestsellers_card {
      width: 31.8%;
    }
  } 
  @media screen and (max-width: 945px) {
    .bestsellers_card {
      width: 50%;
    }
  } 
  @media screen and (max-width: 695px) {
    .bestsellers_card {
      width: 48.3%;
    }
  } 

  @media screen and (max-width: 660px) {
    .bestsellers_card {
      width: 48.2%;
    }
  } 
  @media screen and (max-width: 660px) {
    .bestsellers_card {
      width: 48%;
    }
  } 
  @media screen and (max-width: 590px) {
    .bestsellers_card {
      width: 100%;
    }
  } 

  @media screen and (max-width: 1200px) {
    .page_sort_container  {
        max-width: calc(2 * 300px +  20px)
    }
    .filter_data_container {
        max-width: calc(2 * 300px +  20px)
    }
  }

  @media screen and (max-width: 981px) {
    .page_sort_container  {
        max-width: calc(3 * 300px + 2 *20px)
    }
    .filter_data_container {
        max-width: calc(3 * 300px +   2 *20px)
    }
}

@media screen and (max-width: 500px) {
    .page_sort_container {
        flex-wrap: wrap;
        gap: 20px;
    }
    .page_container {
        width: 100%;
    }
}
@media screen and (max-width: 300px) {
    .filter_by_popularity_container {
       max-width: 220px;
    }
    .filter_data_container {
        max-width: 220px;
       width: 100%;
    }
    .card_container {
       max-width: calc(220px + 40px);
       width: 100%;
       justify-content: center;
    }
}

@media screen and (max-width: 500px) {
    .page_sort_container {
        margin-top: -40px;
    }
}
