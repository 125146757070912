.card_author_container {
    max-width: 450px;
    background: var(--white);
    -webkit-box-shadow: 6px 6px 13px 0px rgba(118, 118, 118, 25%);
    box-shadow: 6px 6px 13px 0px rgba(118, 118, 118, 25%);
}

.card_author_container:hover {
    cursor: pointer;
}

.border_radius_12 {
    border-radius: 12px;
}

.books_text {
    color: var(--mainColor);
    font-size: 16px;
}

.gap_20 {
    gap: 20px;
}

.gap_10 {
    gap: 10px;
}

.padding_20 {
    padding: 20px;
}

.img_container {
    max-width: 180px;
    height: 255px;
}

.author_name {
    font-size: 20px;
    font-weight: 700;
    max-height: 30px;
}

.author_img_container {
    max-width: 180px;
}

.padding_10 {
    padding: 10px;
}

.author_books_container {
    max-width: calc(2 * 100px);
    height: 100%;
    max-height: 250px;
}

.books_img_container {
    width: 90px;
    height: 120px;
    border-radius: 8px;
}

.books_text {
    cursor: pointer;
}

@media screen and (max-width: 470px) {
    .author_img_container {
        max-width: 160px;
    }

    .img_container {
        height: 240px;
    }

    .author_books_container {
        height: 240px;
    }

    .books_img_container {
        height: 110px;
        width: 90px;
    }

    .author_books_container {
        max-width: calc(2 * 90px + 20px);
    }
}

@media screen and (max-width: 450px) {
    .gap_20 {
        gap: 10px;
    }

    .author_name {
        font-size: 18px;
    }

    .books_text {
        font-size: 14px
    }
}

@media screen and (max-width: 420px) {
    .author_img_container {
        max-width: 140px;
    }

    .img_container {
        height: 210px;
    }

    .author_books_container {
        height: 210px;
    }

    .books_img_container {
        height: 100px;
        width: 80px;
    }

    .author_books_container {
        max-width: calc(2 * 80px + 20px);
    }
}

@media screen and (max-width: 400px) {
    .author_img_container {
        max-width: 140px;
    }

    .img_container {
        height: 200px;
    }

    .author_books_container {
        height: 200px;
    }

    .books_img_container {
        height: 90px;
        width: 80px;
    }

    .author_books_container {
        max-width: calc(2 * 80px + 20px);
    }

    .author_name {
        font-size: 16px;
    }

    .books_text {
        font-size: 12px
    }
}

@media screen and (max-width: 375px) {
    .author_img_container {
        max-width: 140px;
    }

    .img_container {
        height: 190px;
    }

    .author_books_container {
        height: 190px;
    }

    .books_img_container {
        height: 90px;
        width: 70px;
    }

    .author_books_container {
        max-width: calc(2 * 70px + 20px);
    }

    .author_name {
        font-size: 14px;
    }
}

@media screen and (max-width: 355px) {
    .img_container {
        height: 180px;
    }

    .author_books_container {
        height: 180px;
    }

    .books_img_container {
        height: 80px;
        width: 60px;
    }

    .author_books_container {
        max-width: calc(2 * 60px + 20px);
    }

}

@media screen and (max-width: 325px) {
    .author_name {
        font-weight: 500;
    }
}

@media screen and (max-width: 320px) {
    .img_container {
        height: 160px;
    }

    .author_books_container {
        height: 160px;
    }

    .books_img_container {
        height: 70px;
        width: 50px;
    }

    .author_books_container {
        max-width: calc(2 * 50px + 20px);
    }
}