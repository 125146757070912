.gap_10 {
    gap: 10px;
}

.gap_20 {
    gap: 20px;
}

.gap_50 {
    gap: 50px;
}

.padding_20 {
    padding: 20px;
}

.padding_50_20 {
    padding: 50px 20px;
}

.item_container {
    max-width: calc(2 * 225px + 25px + 40px);
}

.upload_cartoon_container {
    max-width: 1150px;
    filter: drop-shadow(8px 6px 10px rgba(118, 118, 118, 0.17));
    background: var(--white);
}

.upload_cartoon_data_item {
    width: 45%;
    padding: 20px;
    gap: 25px;
}

.border_right {
    border-right: 1.5px solid var(--passiveButtonColor);
    margin: 20px 0;
}

.upload_cartoon_video_item {
    width: 55%;
}

.progress_bar_container {
    max-width: 300px;
}

.added_cartoon_text {
    font-weight: 500;
    font-size: 14px;
    color: var(--mainColor);
}

.progress_bar {
    width: 500px;
    height: 50px;
}

@media screen and (max-width:980px) {
    .upload_cartoon_container {
        flex-wrap: wrap;
    }

    .border_right {
        border-right: none;
    }

    .dataContainer {
        flex-wrap: wrap;
    }

    .upload_cartoon_data_item {
        width: 100%;
        max-width: 700px;
        height: auto;
        flex-wrap: wrap;
    }

    .upload_cartoon_video_item {
        height: auto !important;
        width: 100%;
    }

    .upload_cartoon_video_item input {
        width: 100%;
    }

    .upload_cartoon_container {
        width: 100%;
    }
}

@media screen and (max-width:375px) {
    .padding_20 {
        padding: 10px;
    }
}

@media screen and (max-width:360px) {
    .padding_50_20 {
        padding: 50px 10px;
    }
}