.category_item {
    -webkit-box-shadow: 2px 2px 13px 0px rgb(9 188 193 / 25%);
    box-shadow: 2px 2px 13px 0px rgb(9 188 193 / 25%);
    background: var(--white);
    height: 60px;
    border-radius: var(--md);
    gap: 12px;
    height: 70px;
    padding: 15px;
}

.box_shadow_none {
    box-shadow: none;
}

.category_item:hover {
    cursor: pointer !important;
}

.active_category {
    border: 1px solid var(--mainColor);
}

.width_20 {
    width: 20px;
}