.blog p {
    color: var(--black);
}

.title {
    font-size: 40px;
    font-weight: 700;
}

.bg {
    background: linear-gradient(90.56deg, var(--mainLineColor) 0%, var(--mainColor) 50.44%, var(--mainColorBackground) 100%);
    border-radius: 0;
    font-size: 40px;
    margin-bottom: 50px;
    color: var(--white) !important;
    padding: 25px
}