.gap_10 {
    gap: 10px;
}

.text_confirm_edit {
    font-weight: 500;
    font-size: 15px;
}
.text_confirm_edit:hover {
    cursor: pointer;
}
.cancel_text {
    color: #969A9B;;
}
.save_text {
    color: var(--mainColor);
}
.title {
    font-size: 17px;
    font-weight: 550;
}

.edit_title input {
    padding: 5px;
    outline: none;
    font-size: 14px;
    font-weight: 420;
    border-bottom: 1px solid var(--mainColor) !important;
}