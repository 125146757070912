.bestsellers_container {
    gap: var(--gap_xl);
}

.cards_container {
    max-width: calc(4 * 300px + 3 * var(--gap_xl));
    justify-content: flex-start;
}

.bestsellersCard {
    width: 100%;
}

.more_books_container {
  min-width: 175px;
  max-width: var(--maxwidth_200);
  padding: 13px;
  border-radius: var(--md);
  font-weight: var(--fontWeight_400);
  background: var(--mainColor);
  color: var(--mainGoogleColor);
  gap: 10px;
  height: 50px;
  padding: 20px;
}

@media screen and (max-width:1315px) {
    .cards_container {
        max-width: calc(3 * 300px + 2 * var(--gap_xl));
    }
}

@media screen and (max-width: 1315px) {
  .bestsellersCard {
    width: 32% ;
  }
}

.data_container {
    background: var(--mainItemColor);
    flex-wrap: wrap;
    gap: var(--gap_3xl);
    padding: 10px 20px 30px 20px;
    display: flex;
}


 @media screen and (max-width: 995px) {
  .bestsellersCard {
    width: 31.9%;
  }
}
@media screen and (max-width: 986px) {
  .bestsellersCard {
    width: 50%;
  }
  .cards_container {
    max-width: calc(2 * 300px + var(--gap_xl));
  }
}

@media screen and (max-width: 675px) {
  .bestsellersCard {
    width: 49%;
  }
}
@media screen and (max-width: 670px) {
  .bestsellersCard {
    width: 100%;
  }
  .cards_container {
    justify-content: center;
  }
}
@media screen and (max-width:625px) {
    .cards_container {
        justify-content: center ;
    }
}
@media screen and (max-width: 500px) {
  .bestsellersCard {
    max-width: 270px;
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .bestsellersCard {
    width: 90%;
  }
}

@media screen and (max-width: 300px) {
  .cardBestseller {
    width: 80%;
  }
}
