.vinni_img_container {
    max-width: var(--maxwidth_200);
    gap: var(--gap_lg);
}

.suggestions_data_container {
    max-width: var(--maxwidth_200);
    gap: var(--gap_md);
}

.suggestions_data_container span {
    font-size: var(--mdFontSize);
    font-weight: var(--fontWeight_600);
}