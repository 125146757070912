.category_name:hover {
    cursor: pointer;
}

.categories_container {
    gap: var(--gap_xl);
    max-width: 1000px;
    padding: 0 20px;
    height: 20%;
    max-height: 440px;
}

.stories_container {
    max-height: var(--maxHeight_50);
    max-width: var(--maxwidth_500);
}

.category_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: calc(45% - 20px);
}

.categories {
    gap: var(--gap_xl);
}

.top_container {
    max-height: var(--maxHeight_290);
}

.bottom_container {
    max-height: var(--maxHeight_sm);
    gap: var(--gap_xl);
}

.bottom_container_item {
    max-width: var(--maxwidth_300);
    max-width: var(--maxwidth_315);
}

.category_name_story {
    bottom: 20px;
    right: 30px;
}

.category_name {
    font-size: var(--xlFontSize);
    font-weight: var(--fontWeight_700);
    color: var(--white);
}

.category_fairy_tale {
    left: 20px;
    top: 20px;
}

.category_detective {
    top: 20px;
    right: 20px;
}

.category_adventure {
    bottom: 20px;
    left: 20px;
}

.categories_item_container {
    max-height: 440px;
    max-width: calc(55% - 10px);
}

@media screen and (max-width:880px) {
    .category_container span {
        font-size: 25px;
    }
}

@media screen and (max-width:815px) {
    .category_image {
        overflow: hidden;
        align-items: center;
    }
}

@media screen and (max-width:770px) {
    .category_container span {
        font-size: 20px;
    }
}

@media screen and (max-width:660px) {
    .categories_container {
        flex-wrap: wrap;
        max-height: none !important;
    }

    .category_image {
        width: 100%;
    }

    .categories_item_container {
        max-width: 500px;
    }

    .category_container span {
        font-size: 25px;
    }
}


@media screen and (max-width: 455px) {
    .bottom_container {
        flex-wrap: wrap;
    }

    .categories_item_container {
        max-height: none;
    }

    .bottom_container {
        max-height: none;
    }

    .bottom_container_item {
        width: 100%;
        max-width: none;
    }
}