.gap_50 {
    gap: 50px;
}

.gap_20 {
    gap: 20px;
}
.card_item_container {
    max-width: calc(450px * 3 + 2 * 20px);
}
.padding_0_20 {
    padding: 0 20px;
}

@media screen and (max-width:1430px) {
    .card_item_container {
        max-width: calc(450px * 2 +  20px + 40px);
    }
}
@media screen and (max-width: 960px){
    .card_item_container {
        justify-content: center;
    }
}