.card_container {
  width: 23%;
}

.card_item {
  max-height: var(--maxHeight_480);
  padding: 20px 10px 20px 10px;
  min-height: 470px;
  border-radius: var(--xl);
  background: var(--white);
}

.max_width_300 {
  max-width: var(--maxwidth_300);
}

.card_item_img_container {
  height: 300px;
}

.card_item_rating_container {
  gap: var(--gap_xl);
  max-width: var(--maxwidth_200);
}

.bestsellers {
  max-width: var(--maxwidth_200);
  min-width: var(--maxwidth_200);
}

.bestsellers img {
  max-height: var(--height_300);
  border-radius: var(--md);
}

.card_bestseller {
  min-width: var(--maxwidth_200);
  border-radius: 10px;
  -webkit-box-shadow: 8px 6px 13px 0px rgba(118, 118, 118, 25%);
  max-width: 300px;
}

.padding_0_20 {
  padding: 0 20px;
}

.ellipsis {
    text-overflow: ellipsis;
}