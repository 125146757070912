.cancelLogout {
  transition: all 0.2s ease-in;
}
.cancelLogout:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 2px var(--mainColor);
}

.confirm_logOut_container {
  gap: 20px;
}

.confirm_logOut_container button {
  width: 140px;
  font-size: 14px;
  height: 40px;
  border-radius: var(--sm);
}

.confirm {
  color: var(--mainColor);
  box-shadow: 2px 3px 4px rgba(133, 224, 226, 0.5);
  border-radius: 5px;
  background: var(--white);
} 

.disapprove {
  color: var(--white);
  background: var(--mainColor);
}
