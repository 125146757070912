.age_group_container {
    gap: 50px;
}

.title {
    color: var(--black);
}

.title_edit {
    font-size: var(--mdFontSize);
    max-width: 50px;
}

.active_age {
    border: 1px solid var(--mainColor);
    border-radius: 8px;
    min-width: 60px;
    padding: 5px 0;
    color: var(--mainColor);
    font-weight: 600;
}

.item:hover {
    color: var(--mainColor);
    cursor: pointer;
}

.age_title {
    min-width: 36px;
}

@media screen and (max-width: 826px) {
    .age_group_container {
        gap: 30px;
    }
}

@media screen and (max-width: 690px) {
    .age_group_container {
        gap: 30px;
    }
}

@media screen and (max-width: 465px) {
    .age_group_container {
        gap: 10px;
    }
}

@media screen and (max-width: 390px) {
    .age_group_container {
        min-width: 270px;
        overflow-x: scroll !important;
    }
}

@media screen and (max-width: 365px) {
    .age_group_container {
        gap: 20px;
    }

    .title_edit {
        font-size: var(--smFontSize);
    }
}

@media screen and (max-width: 365px) {
    .age_group_container {
        gap: 15px;
    }
}

@media screen and (max-width: 365px) {
    .title_edit {
        font-size: 14px;
    }
}