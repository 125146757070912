.bg_vinni {
    background-color: var(--mainColor);
    padding: 50px;
    min-height: 600px;
}

.author_main {
    width: 55%;
    min-width: 300px;
}

.author_info {
    background-color: var(--white);
    padding: 50px 0;
    border: 1px solid;
    border-radius: 12px;
    min-height: 500px;
}

.empty_div {
    width: 10%;
}

.about_content {
    width: 80%;
}

.about_content>h5 {
    text-align: center;
    color: var(--black);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 25px;
}
.about_content>p {
    color: var(--black);
    font-size: 14px;
}
.about_content>p {
    color: var(--black);
    max-height: 65vh;
}

.text_elipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
}

.more_button {
    margin-top: 10px;
    background-color: var(--mainColor) !important;
    color: var(--white);
    padding: 5px 15px;
    border-radius: 3px;
    opacity: 1;
    font-weight: 600;
    font-style: italic;
    min-width: 150px;
}
.more_button:active {
    border: 0;
}

.author_img {
    max-width: 250px;
    right: 70%;
}
.author_img img {
    border-radius: 8px;
}

.description {
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.read_more_container {
    -webkit-line-clamp: 20;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.read_more_container, .description:hover {
    cursor: pointer;
}

@media screen and (max-width: 1600px) {
    .about_content {
        width: 70%;
    }
}

@media screen and (max-width: 1425px) {
    .author_main {
        width: 60%;
    }
}

@media screen and (max-width: 1250px) {
    .author_main {
        width: 80%;
    }
}

@media screen and (max-width: 950px) {
    .about_content {
        width: 60%;
    }
    .author_img {
        right: 65%;
    }
}

@media screen and (max-width: 820px) {
    .author_img {
        left: auto;
        top: 185px !important;
        max-height: 280px;
        max-width: 200px;
        width: 100%;
        right: auto;
    }
    .bg_vinni {
        min-height: 760px;
        align-items: end;
    }

    .author_main {
        width: 100%;
    }

    .author_info {
        justify-content: center !important;
        min-height: 520px;
        align-items: end !important;
    }
    .empty_div {
        width: 0%;
    }
}
