.audio_player {
    --player-button-width: 3em;
    --sound-button-width: 2em;
    /* --space: .5em;
    width: 15rem; */
  }
  
  .current_time {
    font-size: 12px;
  }
  
  .current_time_container {
    padding: 10px;
  }
  
  .audio_container {
    height: 25px;
    padding-top: 10px;
  }
  
  .controls {
    gap: 40px;
    margin-top: 10px;
  }
  
  .timeline {
    appearance: none;
    max-width: 250px;
    height: 2px;
    background-color: #e5e5e5;
    border-radius: 5px;
    background-size: 0% 100%;
    background-image: linear-gradient(var(--mainColor), var(--mainColor));
    background-repeat: no-repeat;
    margin-right: var(--space);
  }
  .timeline:focus-visible {
    outline: none;
  }
  .timeline::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all .1s;
    background-color: var(--mainColor) !important;
  }
  
  .timeline::-moz-range-thumb {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all .1s;
    background-color: var(--mainColor);
  }
  
  .timeline::-ms-thumb {
    appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all .1s;
    background-color: var(--mainColor);
  }
  
  .timeline::-webkit-slider-thumb {
    background-color: var(--mainColor) !important;
  }
  
  .timeline::-webkit-slider-thumb {
    opacity: 1;
  }
  
  .timeline::-moz-range-thumb {
    opacity: 1;
  }
  .timeline::-ms-thumb {
    background-color: var(--mainColor) !important;
  }
  
  .audio_container::-webkit-media-controls-play-button,
  .audio_container::-webkit-media-controls-panel {
    background-color: var(--white) !important;
  }
  
  .audio_container::-webkit-media-controls-mute-button {
    display: none !important;
  }
  
  .audio_container::-webkit-media-controls-mute-button:hover {
    display: none !important;
    opacity: 1;
  }
  
  .audio_container::-webkit-media-controls-play-button {
    display: none !important;
  }
  
  .audio_container::-webkit-media-controls-timeline-container {
    display: none !important;
  }
  
  .audio_container::-webkit-media-controls-time-remaining-display {
    display: none !important;
  }

  .audio_container::-webkit-media-controls-timeline,
  video::-webkit-media-controls-timeline {
    display: none;
  }
  
  .audio_container::-webkit-media-controls-time-remaining-display {
    display: block !important
  }