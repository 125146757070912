.phone_input {
    height: 45px
}

.height_56 {
    height: 56px;
}

.react-tel-input .selected-flag { 
    border-right: none !important;
}

.drop_down {
    width: 350px !important;
}
