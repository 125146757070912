.volume {
  padding-right: 10px;
  cursor: pointer;
}

.player_icon {
  width: 20px;
  height: 20px;
}

.fill_white {
  filter: brightness(0) invert(1);
}

.volume_progress_img {
  padding: 10px 0;
  visibility: hidden;
  width: 60px;
}
.margin_auto_0 {
  margin: auto 0;
}

.relative {
  width: 100%;
}

.cursor_pointer {
  cursor: pointer;
}

.volume_progressa {
  width: 60px;
}

.volume_img {
  width: 25px;
}

.width_0 {
  width: 0;
}