.suggestions_container {
    gap: var(--gap_3xl);
}

.suggestions_img_container {
    padding: 0 20px 10px 20px;
}

@media screen and (max-width:620px) {
    .suggestions_img_container {
        flex-wrap: wrap;
    }
}