.carttoon_info {
    height: 35px;
    outline: none;
    font-weight: 500;
    padding: 5px;
    font-size: 14px;
}

.height_100 {
    height: 100px;
}

.description_input {
    border-bottom: 1px solid var(--mainColor) !important;
}

.description_input:focus-visible {
    box-shadow: none !important;
    outline: none !important;
}

@media screen and (max-width: 455px) {
    .carttoon_info {
        font-size: 14px;
    }
}