.settings {
    padding: 20px 0;
    gap: 10 0;
}

.settings>a,
.login,
.settings>button {
    padding: 15px 30px;
    display: flex;
    color: var(--black);
    gap: 10px;
    align-items: center;
    box-shadow: 0px 3px 3px 3px rgba(0, 168, 187, 0.25);
    margin-bottom: 10px;
}

.margin_right_10 {
    margin-right: 10px;
}

.settings a,
.settings button {
    font-weight: 600;
    font-size: 1rem;
    color: var(--black);
}

.settings img {
    height: 30px;
    width: 30px;
}

.settings h6 {
    margin: 0;
    font-weight: 600;
}

.settings>a:hover,
.settings>button:hover,
.login:hover {
    background-color: #09bbc13a;
}

.contact_us {
    padding: 15px 30px;
}

.other_img {
    width: 22px !important;
    height: 22px !important;
}

.padding_left_34 {
    padding-left: 34px !important;
}