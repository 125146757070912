.single_product_page_container {
    gap: var(--gap_xl);
    min-width: 120px;
}

.selected_book_data_container {
    background: var(--mainColorBackground);
}

.selected_book_data {
    border: 1px solid var(--black);
    background: var(--white);
    max-width: 800px;
    height: 460px;
    border-radius: var(--xl);
    gap: 35px
}

.img_container {
    max-width: 250px;
    z-index: 1;
    margin-right: -100px;
}

.img_container img {
    border-radius: 8px;
}

.single_book_data_container {
    padding: 20px;
    gap: 20px;
}

.name_title {
    font-weight: var(--fontWeight_600);
    color: var(--black);
    font-size: var(--lgFontSize);
}

.padding {
    padding: 0 20px 0 0;
}

.margin_top {
    margin-top: 50px;
}

.books_data_container {
    padding: 20px 0;
}

.want_read_container {
    width: 18px;
}

.want_read_container:hover {
    cursor: pointer;
}

.mark_as_read {
    color: var(--mainColor);
}

.first_child {
    display: table-footer-group;
}

@media screen and (max-width:845px) {
    .img_container {
        max-width: 230px;
    }
}


@media screen and (max-width:820px) {
    .second_child {
        display: table-header-group !important;
    }

    .display_contents {
        display: contents;
    }

    .single_book_data_container {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 200px;
    }

    .children_container {
        display: block !important;
    }

    .selected_book_data {
        position: relative;
        flex-wrap: wrap;
        padding: 200px 20px 20px;
    }

    .img_container {
        margin-right: 0;
        position: absolute;
        top: 20px;
    }

    .single_product_page_container {
        text-align: -webkit-center;
        position: relative;
    }

    .selected_book_data {
        height: auto;
    }

    .padding {
        padding: 0 20px;
    }

    .books_data_container {
        padding: 0 0 20px 0;
    }

    .name_title {
        margin-left: 40px;
    }

    .mark_as_read {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width:500px) {
    .single_product_page_container span {
        font-size: 16px;
    }

    .authorName {
        font-size: 14px !important
    }

    .img_container {
        max-width: 235px;
    }
}

@media screen and (max-width:425px) {
    .name_title {
        font-size: 14px !important;
    }

    .img_container {
        max-width: 220px;
    }

    .single_book_data_container {
        margin-top: 150px;
        width: 100%;
    }
}

@media screen and (max-width:345px) {
    .single_book_data_container {
        width: 100%;
        margin-top: 150px;
    }

    .gap50 {
        width: 100%;
    }

    .img_container {
        max-width: 200px;
    }
}

@media screen and (max-width:320px) {
    .flexWrap {
        flex-wrap: wrap;
    }
}