.description {
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.reverse {
    flex-direction: row-reverse
}

.content {
    gap: 25px;
}

.content img {
    max-width: 350px;
    margin: auto;
}