.timeline_change {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.gap_5 {
  gap: 5px;
}

.align_items_center {
  align-items: center;
}

.max_width_none {
  max-width: 100% !important;
}

.right_10 {
  right: 10px;
}

.display_flex {
  display: flex;
}

.video_items_container div img:hover {
  cursor: pointer;
}

.volume_container {
  width: 50px;
}

.video_items_container {
  bottom: 2px;
  z-index: 1;
  border-radius: 10px;
  padding: 0 15px 10px 15px;
}

.iframe_container:hover {
  cursor: pointer;
}

.border_radius_10 {
  border-radius: 10px;
}

.video_container {
  display: flex;
  width: 100%;
  align-items: center;
  object-fit: cover;
}

.controls {
  display: flex;
  position: absolute;
  justify-content: center;
  gap: 20px;
  width: 100%;
  align-items: center;
  transition: opacity .2s;
}

.img_container {
  width: 18px;
  height: 18px;
}

.img_container img {
  width: 100%;
  height: 100%;
}

.video_items_container input:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: none !important;
}

.timeline {
  appearance: none;
  width: 100%;
  max-width: 250px;
  height: 2px;
  background-color: #e5e5e5;
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(var(--mainColor), var(--mainColor));
  background-repeat: no-repeat;
  margin-right: var(--space);
}

.timeline:focus-visible {
  outline: none;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: var(--mainColor) !important;
}

.timeline::-moz-range-thumb {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: var(--mainColor);
}

.timeline::-ms-thumb {
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: var(--mainColor);
}

.timeline::-webkit-slider-thumb {
  background-color: var(--mainColor) !important;
}

.timeline::-webkit-slider-thumb {
  opacity: 1;
}

.timeline::-moz-range-thumb {
  opacity: 1;
}
.timeline::-ms-thumb {
  background-color: var(--mainColor) !important;
}

.volume_time_line {
  max-width: 20px;
}

@media screen and (max-width:980px) {
  .video_container {
    max-height: none;
    height: 100%;
  }
}



