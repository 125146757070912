.title {
    font-weight: var(--fontWeight_600);
    color: var(--black);
}

.gap_20 {
    gap: 20px;
}

.user_data_item_width {
    width: calc(3 * 220px + 2 * 20px);
}

.padding_50 {
    padding: 50px 20px 0 20px;
}

@media screen and (max-width:715px) {
    .user_data_item_width {
        width: calc(3 * 200px + 2 * 20px);
    }
}

@media screen and (max-width:682px) {
    .user_data_item_width {
        width: calc(3 * 180px + 2 * 20px);
    }
}

@media screen and (max-width:622px) {
    .user_data_item_width {
        width: calc(2 * 225px + 20px);
    }
}

@media screen and (max-width:600px) {
    .user_data_item_width {
        width: calc(2 * 220px + 20px);
    }
}

@media screen and (max-width: 510px) {
    .user_data_item_width {
        width: calc(2 * 210px + 20px);
    }
}

@media screen and (max-width: 490px) {
    .user_data_item_width {
        width: calc(2 * 200px + 20px);
    }
}

@media screen and (max-width: 470px) {
    .user_data_item_width {
        width: calc(2 * 180px + 20px);
    }
}

@media screen and (max-width: 430px) {
    .user_data_item_width {
        width: calc(2 * 160px + 20px);
    }
}

@media screen and (max-width: 390px) {
    .user_data_item_width {
        width: 100%;
    }
}