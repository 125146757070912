@import url('../../../styles/Variables.css');

.tab_bar_container {
  z-index: 4;
  padding: 15px 20px 25px 20px;
  border-top: 1px solid aqua;
  background-color: var(--white);
  height: 100px;
}

.tab_bar_menues {
  flex: 1;
}

.tab_bar_menues span {
    color: var(--mainLineColor);
    font-size: 14px;
}

.tab_bar_icon {
  width: 30px;
}

.tab_bar_display {
  display: none;
}

@media screen and (max-width:950px) {
  .tab_bar_container {
    display: block;
    z-index: 3000;
  }
  .tab_bar_display {
    display: block
  }
}

@media screen and (max-width:445px) {
  .tab_bar_icon {
   width: 25px;
  }
}

@media screen and (max-width:315px) {
  .tab_bar_icon {
   width: 20px;
  }
  .tab_bar_container {
    padding: 15px;
  }
}
