.category_card {
    max-width: 225px;
}

.gap_5 {
    gap: 5px;
}

.categories_item_container {
    gap: 25px;
    max-width: calc(2 * 225px + 20px + 40px);
}

@media screen and (max-width:1185px) {
    .category_card {
        max-width: 220px;
    }

    .categories_item_container {
        max-width: calc(2 * 220px + 25px + 40px);
    }
}

@media screen and (max-width:1165px) {
    .category_card {
        max-width: 215px;
    }

    .categories_item_container {
        max-width: calc(2 * 215px + 25px + 40px);
    }
}

@media screen and (max-width:1140px) {
    .category_card {
        max-width: 210px;
    }

    .categories_item_container {
        max-width: calc(2 * 210px + 25px + 40px);
    }
}

@media screen and (max-width:1120px) {
    .category_card {
        max-width: 200px;
    }

    .categories_item_container {
        max-width: calc(2 * 200px + 25px + 40px);
    }
}

@media screen and (max-width:1075px) {
    .category_card {
        max-width: 190px;
    }

    .categories_item_container {
        max-width: calc(2 * 190px + 25px + 40px);
        gap: 25px;
    }
}

@media screen and (max-width:1030px) {
    .category_card {
        max-width: 180px;
    }

    .categories_item_container {
        max-width: calc(2 * 180px + 25px + 40px);
    }
}

@media screen and (max-width:985px) {
    .category_card {
        max-width: 170px;
    }

    .categories_item_container {
        max-width: calc(2 * 170px + 25px + 40px);
    }
}

@media screen and (max-width:980px) {
    .category_card {
        max-width: 225px;
    }

    .categories_item_container {
        max-width: calc(2 * 225px + 25px + 40px);
    }
}