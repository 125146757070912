.read_book_container {
    height: calc(100vh - 100px);
}

.read_book_container p {
    color: var(--white);
}

.transparent_container {
    height: 100vh;
    z-index: 100;
}

.page_container {
    right: 1rem;
    left: 1rem;
    z-index: 1;
    color: var(--black);
    margin-top: 50px;
}

.color_white {
    color: var(--white) !important;
}