.pageination_container {
    height: var(--height_36);
}

.pageination_data {
    padding: 5px 10px;
    background: var(--white);
    max-width: 400px;
    min-width: 200px;
    box-shadow: 2px 2px 4px -1px rgba(9, 188, 193, 0.26);
    border-radius: var(--xl);
    height: 40px;
}

.pageination_data button {
    border: none;
    background: none;
    color: var(--mainColor);
    font-size: var(--smFontSize);
}

.pageination_my_books {
    bottom: 200px;
}