.data_container {
    margin-left: 120px;
    width: 30%;
    max-width: 150px;
    padding-top: 20px;
}

.book_parametrs {
    max-width: 200px;
    padding: 20px 0;
    gap: var(--gap_3xl);
}

.title_container {
    font-weight: var(--fontWeight_600);
    color: var(--black);
}

.font_size_20 {
    font-size: var(--lgFontSize);
}

@media screen and (max-width:820px) {
    .book_parametrs {
        flex-wrap: nowrap !important;
        max-width: none;
    }

    .data_container {
        width: 100%;
        max-width: none;
        padding: 0 10px;
    }

    .padding {
        padding: 0 20px;
    }
}

@media screen and (max-width:700px) {
    .font_size_20 {
        font-size: 16px;
    }

    .book_parametrs {
        gap: 15px;
    }

    .font_size_20 {
        min-height: 35px;
    }
}

@media screen and (max-width:550px) {
    .font_size_20 {
        font-size: 14px !important;
    }
}

@media screen and (max-width:470px) {
    .book_parametrs {
        flex-wrap: wrap !important;
    }
}

@media screen and (max-width:345px) {
    .font_size_20 {
        min-width: 40px;
    }
}

@media screen and (max-width:325px) {
    .book_parametrs {
        overflow-x: scroll;
        padding-bottom: 10px;
    }
}