.padding_5 {
    padding: 5px;
}

.brightness_container {
    background: #eaeaea;
    border-radius: var(--md);
    height: 32px;
}
.brightness_container button {
    font-size: 13px;
    padding: 3px;
    width: 65px;
}
.height_30 {
    height: 30px;
}

.darck_mode {
    padding: 3px 0 3px 0;
}

.active_button {
    background: #FFFFFF !important;
    border-radius: 5px;
    padding: 5px;
}






