.error_container {
    gap: 20px;
}

.img_container {
    max-height: 170px;
}

.text_container span {
    font-size: 20px;
    font-weight: 600;
}

.error_img {
    max-width: 180px;
}