.profile_parametrs_container {
    min-height: 100px;
}

.profile_icon_container {
    width: var(--width_50);
    -webkit-appearance:none !important;
    appearance: none !important;
}

.profile_dropdown_visible {
    border-radius: 0px 0px 12px 12px;
    z-index: 2500;
    border: 1px solid var(--mainLineColor);
    border-top: none;
    background-color: var(--white);
}

.profile_parametrs {
    padding: 10px 15px;
    width: 100%;
}

.profile_parametrs button {
    font-size: var(--mdFontSize);
    max-height: 25px;
    padding: 0;
    z-index: 3000;
}

.line {
    border-bottom: 1px solid var(--mainLineColor);
}

.profile_parametrs a {
    font-style: normal;
    font-size: var(--mdFontSize);
    color: var(--black);
    z-index: 3000;
}

.link:hover{
  color: var(--mainColor);
}

.profile_data {
    max-width: 250px;
    top: 100px;
    right: 30px;
    height: 0;
    z-index: 2000;
    background-color: var(--white);
    transition: all 500ms;
    transition-timing-function: ease-in-out;
    border-radius: 0 0 12px 12px;
    box-shadow: 0px 4px 13px 2px rgb(9 188 193 / 25%);
}

.profile_animated {
    height: 330px;
}
.height_273 {
    height: 273px;
}

.padding_15 {
    padding: 15px;
}

@media screen and (max-width: 950px) {
    .profile_parametrs_container  { 
       display: none !important;
    }
  }
