.buttons_container {
    gap: 20px;
}

.buttons_container button {
    font-weight: var(--fontWeight_400);
    font-size: var(--mdFontSize);
}

.cancel_button_container {
    color: #969A9B;
}

.save_button_container {
    min-width: 130px;
    background: var(--mainColor) !important;
    color: var(--white);
    border-radius: var(--sm);
    padding: 5px;
}