.my_account_container {
    max-width: var(--maxwidth_md);
    border-bottom: 1px solid var(--mainColor);
}

.my_account_title {
    font-size: var(--xlFontSize);
}

.title {
    font-weight: var(--fontWeight_600);
    color: var(--black);
}

.title_container {
    gap: var(--gap_xl);
}

@media screen and (max-width:900px) {
    .title_container {
        width: 100%;
    }
}