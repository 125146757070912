.playlist_item_container {
    max-width: calc(6 * 300px + 40px);
    padding:50px 20px;
}

@media screen and (max-width:1840px) {
    .playlist_item_container {
        max-width: calc(5 * 300px + 40px);
    }
}

@media screen and (max-width:1540px) {
    .playlist_item_container {
        max-width: calc(4 * 300px + 40px);
    }
}

@media screen and (max-width:1250px) {
    .playlist_item_container {
        max-width: calc(3 * 300px + 40px);
    }
}

@media screen and (max-width:950px) {
    .playlist_item_container {
        max-width: calc(2 * 300px + 40px);
    }
}

@media screen and (max-width:650px) {
    .playlist_item_container {
        max-width: calc(300px + 40px);
    }
}