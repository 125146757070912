.card_item_container_genre {
    color: var(--greyTextColor);
    margin-right: 10px;
}

.data_container {
    text-overflow: ellipsis;
    color: var(--greyTextColor);
}
  .category_image {
    width: 15px !important;
    height: 15px !important;
    margin: 0 3px !important;
  }