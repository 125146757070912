.upload_cartoon_age_group_container {
    height: var(--height_50);
    gap: 10px;
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
    background: var(--white);
    padding: 10px 20px;
}

.gap_5 {
    gap: 5px;
}

.active_age {
    border: 1px solid var(--mainColor);
    border-radius: 8px;
    min-width: 60px;
    padding: 5px 0;
    color: var(--mainColor);
    font-weight: 600;
}

.item:hover {
    color: var(--mainColor);
    cursor: pointer;
}

.min_width_60 {
    min-width: 60px;
}

@media screen and (max-width:485px) {
    .upload_cartoon_age_group_container {
        width: 100%;
    }
}

@media screen and (max-width:425px) {
    .upload_cartoon_age_group_container {
        width: 100%;
        max-width: 340px;
    }
}

@media screen and (max-width:400px) {
    .upload_cartoon_age_group_container {
        gap: 0;
        padding: 0;
    }
}

@media screen and (max-width:345px) {
    .item {
        font-size: 14px;
    }
}

@media screen and (max-width:330px) {
    .upload_cartoon_age_group_container {
        gap: 10px;
    }
}
@media screen and (max-width:330px) {
    .upload_cartoon_age_group_container {
        gap: 5px;
    }
}