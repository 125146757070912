.max_width_800 {
    max-width: 800px;
}

.gap_20 {
    gap: 20px;
}

.paddingRight_bottom_20 {
    padding: 0 20px 10px 0;
}

.playlist_item_container {
    inset: 0;
}

.playlist_item_container:hover {
    overflow-y: scroll !important;
    inset: 0;
}

.playlist_item_container::-webkit-scrollbar {
    width: 12px;
}

.playlist_item_container::-webkit-scrollbar-track {
    background: transparent;
}

.playlist_item_container::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
    border: 3px solid var(--white);
}

@media screen and (max-width: 860px) {
    .playlist_item_container {
        height: calc(100% - 150px) !important;
    }
}