.max_height_100 {
    max-height: 100px;
}
.gap_10 {
    gap: 10px;
}

.padding_20 {
    padding: 20px;
}

.height_80 {
    height: 80px;
}

.img_container img {
    border-radius: 12px;
}

.max_width_700 {
    max-width: 700px;
}

.gap_20 {
    gap: 20px;
}

.width_140 {
    width: 140px;
}

.max_height_80 {
    max-height: 80px;
}
.width_80 {
  width: 80% !important;
}

.playlist_item_container {
    background-color: var(--white);
    -webkit-filter: drop-shadow(13px 4px 6px rgba(118, 118, 118, 0.17));
    border-radius: 12px;
    height: calc(100vh - 120px - 150px);
    margin-right: 20px;
}

.title {
    font-size: 17px;
    font-weight: 550;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    color: var(--black);
}

.font_size_12 {
    font-size: 12px;
}

.color_grey {
    color: var(--greyTextColor);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.delete_button {
    width: 20px;
}

.delete_button:hover {
    cursor: pointer;
}
.curs_pointer:hover {
    cursor:pointer;
}

.playlit_item_data {
  width: 75%;
}

@media screen and (max-width:1200px) {
  .max_width_700 {
    width: 70%;
  }
}

@media screen and (max-width:1215px) {
  .max_width_700 {
    max-width: 650px;
  }
}

@media screen and (max-width:1160px) {
  .max_width_700 {
    max-width: 620px;
  }
}

@media screen and (max-width:1140px) {
    .max_width_700 {
      max-width: 600px;
    }
  }

  @media screen and (max-width:1120px) {
    .max_width_700 {
      max-width: 580px;
    }
  }

  @media screen and (max-width:1020px) {
    .max_width_700 {
      max-width: 560px;
    }
  }

  @media screen and (max-width:1070px) {
    .max_width_700 {
      max-width: 560px;
    }
  }

  @media screen and (max-width:990px) {
    .max_width_700 {
      max-width: 540px;
    }
  }

  @media screen and (max-width:970px) {
    .max_width_700 {
      max-width: 520px;
    }
  }
  @media screen and (max-width:950px) {
    .playlit_item_data {
      width: 65%;
    }
  }

  @media screen and (max-width:940px) {
    .max_width_700 {
      max-width: 500px;
    }
  }

  @media screen and (max-width:920px) {
    .max_width_700 {
      max-width: 480px;
    }
  }

  @media screen and (max-width:910px) {
    .playlit_item_data {
      width: 60%;
    }
  }

  @media screen and (max-width:900px) {
    .max_width_700 {
      max-width: 460px;
    }
  }

  @media screen and (max-width:880px) {
    .max_width_700 {
      max-width: 440px;
    }
  }

  @media screen and (max-width:860px) {
    .max_width_700 {
      max-width: 97%;
    }

    .playlit_item_data {
      width: 75%;
    }
  }

  @media screen and (max-width:600px) {
    .playlit_item_data {
      width: 75%;
    }
  }

  @media screen and (max-width:580px) {
    .playlit_item_data {
      width: 65%;
    }
  }

  @media screen and (max-width:560px) {
    .playlit_item_data {
      width: 60%;
    }
  }

  @media screen and (max-width:540px) {
    .playlit_item_data {
      width: 55%;
    }
  }

  @media screen and (max-width:520px) {
    .playlit_item_data {
      width: 50%;
    }
  }


  @media screen and (max-width: 380px) {
    .width_140 {
      width: 100px;
    }
    .height_80 {
      height: 60px;
    }

    .gap_20 {
      gap: 10px;
    }
    .title {
      font-size: 14px;
    }

    .color_grey {
      font-size: 12px;
    }
  }
  