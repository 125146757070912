.font_family_container {
    padding: 10px;
}
.font_family_select {
    max-width: 100px;
    border: 1px solid #cfd9dc;
    border-radius: 5px;
    padding: 5px;
}
.font_family_select:focus-visible {
    outline: none;
}

.font_family_select:hover {
    cursor: pointer;
}