.max_width_1000 {
    max-width: 1000px;
}

.haight_450 {
    height: 450px;
}

.full_height {
    height: 100vh
}

.description_container {
    border-radius: 12px 0 0 12px;
    background: linear-gradient(to right top, #0ca8a3, #10b3af, #14bdbb, #18c8c7, #1cd3d3)
}

.border_radus_12 {
    border-radius: 12px;
}

.box_shedow {
    box-shadow: 5px 5px 10px 0px rgba(34, 60, 80, 0.2);
}

.color_white {
    color: var(--white);
}

.margin_top_20 {
    margin-top: 20px;
}

.max_width_50 {
    max-width: 50%;
}

.padding_20 {
    padding: 20px;
}

.padding_40 {
    padding: 40px;
}

.haight_data_item {
    height: calc(3 * 50px + 2 * 20px);
}

.gap_20 {
    gap: 20px;
}

.delete_container {
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
}

.color_red {
    color: var(--errorTextColor);
}

.padding_10 {
    padding: 10px;
}

.text_container {
    width: 80%;
}

.text_container span {
    font-size: 20px;
}

.wait_while_is_authorizing {
    height: 100vh;
    width: 100vw;
    z-index: 5000;
    background-color: rgba(240, 240, 240, .3);
}

.gap_10 {
    gap: 10px;
}

.font_weight_600 {
    font-weight: 600;
}

.font_weight_400 {
    font-weight: var(--fontWeight_400);
}

.font_size_18 {
    font-size: var(--mdFontSize);
}

@media screen and (max-width: 650px) {
    .flex_wrap {
        flex-wrap: wrap;
    }

    .max_width_50 {
        max-width: 100%;
    }

    .description_container {
        border-radius: 12px 12px 0 0;
        width: 100% !important;
        padding: 20px;
    }

    .flex_wrap {
        height: auto;
    }
}