.hash_tag_container {
    border-radius: var(--md);
}

.max_height_90 {
    min-height: 90px;
}

.hash_tag_item_container {
    -webkit-box-shadow: 1px 2px 7px 2px rgb(9 188 193 / 25%);
    box-shadow: 1px 2px 7px 2px rgb(9 188 193 / 25%);
    background: var(--white);
    min-height: 35px;
    border-radius: 5px;
    gap: 20px;
}

.hash_tag_item_container span {
    font-size: 15px;
}

.hash_tag_img_container {
    max-width: 10px;
}

.gap_10 {
    gap: 10px;
}

.padding_5 {
    padding: 5px;
}

.hash_tag_img_container:hover {
    cursor: pointer;
}

@media screen and (max-width:915px) {
    .tag_container {
        width: 100%;
        padding: 20px 0;
    }
}