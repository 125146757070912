.active_buttons {
    color: var(--mainColor);
    font-size: 18px;
}

.font_size_18 {
    font-size: 18px;
}

.font_size_12 {
    font-size: 16px;
}

.card_item_label:hover {
    cursor: pointer;
}

.tabs {
    height: 35px;
    font-size: 16px;
    font-weight: 500;
}

.language_container {
    gap: 5px;
}

.tab_img_container {
    max-width: 30px;
}

.tab_img_container img {
    width: 30px;
    height: 20px;
}

.tab:hover {
    cursor: pointer;
} 

.cursor_auto {
    cursor: auto !important;
}

@media screen and (max-width:410px) {
    .font_size_12 {
        font-size: 10px !important;
    }
}

@media screen and (max-width:325px) {
    .font_size_12 {
        font-size: 9px !important;
    }
}