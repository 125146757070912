.transition {
    transition: all 500ms;
    transition-timing-function: ease-in-out;
}

.animation {
  animation: animation 0.5s ease-out forwards;
}

.animation_none {
  opacity: 1;
  transition: all 0ms;
  animation: none;
}

.data_container {
  gap: 5px;
}

@keyframes animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }