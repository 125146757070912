.product_img_container {
    max-width: 200px;
    max-height: 300px;
}
.product_img_container img {
    border-radius: 8px;
}

@media screen and (max-width: 390px) {
    .product_img_container {
        max-width: 180px;
    }
}

@media screen and (max-width: 360px) {
    .product_img_container {
        max-width: 160px;
        max-height: 235px;
    }
} 

@media screen and (max-width: 325px) {
    .product_img_container {
        max-width: 140px;
    }
} 