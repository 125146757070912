.cartoon_cards {
    width: 320px;
    height: 240px;
    background: var(--white);
    color: var(--black);
    box-shadow:10px 9px 14px rgb(118, 118, 118, 0.17);
}
.max_height_240 {
    max-height: 240px;
}

.cartoon_cards:hover {
    color: var(--black);
}

.border_radius_10 {
    border-radius: 10px;
}

.padding_0_15 {
    padding: 0 15px;
}

@media screen and (max-width:1021px) {
    .cartoon_cards {
        width: 300px;
    }
}
@media screen and (max-width:980px) {
    .cartoon_cards {
        width: 300px;
    }
}

@media screen and (max-width:980px) {
    .cartoon_cards {
        width: 320px;
    }
}

@media screen and (max-width:735px) {
    .cartoon_cards {
        width: 300px;
    }
}
@media screen and (max-width:701px) {
    .cartoon_cards {
        width: 300px;
    }
}

@media screen and (max-width:650px) {
    .cartoon_cards {
        width: 280px;
    }
}

@media screen and (max-width:610px) {
    .cartoon_cards {
        width: 360px;
    }
}

@media screen and (max-width:400px) {
    .cartoon_cards {
        width: 300px;
    }
}

@media screen and (max-width:340px) {
    .cartoon_cards {
        width: 280px;
    }
}

@media screen and (max-width:310px) {
    .cartoon_cards {
        width: 260px;
    }
}