.read_more {
    color: var(--mainColor);
}

.read_more_container {
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.read_more_container:hover {
    cursor: pointer;
}

.description {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.description:hover {
    cursor: pointer;
}