.title {
    font-size: 18px;
    font-weight: 500;
}
.title:hover{
    cursor: pointer;
}

@media screen and (max-width:455px) {
    .title {
        font-size: 16px;
    }
}
