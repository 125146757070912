.gap_25 {
    gap: 40px;
}

.width_30_percent {
    width: 30%;
}

.bg {
    background: linear-gradient(90.56deg, var(--mainLineColor) 0%, var(--mainColor) 50.44%, var(--mainColorBackground) 100%);
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 50px;
}

.gap_5 {
    gap: 5%;
}

.max_width_48_percent {
    max-width: 48%;
}
