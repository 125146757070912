.playlists {
    max-height: 320px;
}

.playlists::-webkit-scrollbar {
    width: 12px;
}

.playlists::-webkit-scrollbar-track {
    background: transparent;
}

.playlists::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
    border: 3px solid var(--white);
}

.checkbox {
    margin-right: 10px;
}

.playlist_name {
    color: var(--black);
}

.more {
    background: none;
    color: var(--mainColor);
}

.cancel  {
    color: var(--mainColor);
    padding: 5px 15px;
    margin-top: 10px;
    font-weight: 600;
}

.save {
    margin-top: 10px;
    background-color: var(--mainColor);
    color: var(--white);
    padding: 5px 15px;
    border-radius: 3px;
    opacity: 1;
    font-weight: 600;
}

.save:disabled {
    opacity: 0.6;
}