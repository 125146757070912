body {
    padding-right: 0 !important;
}

.cancel_button{
    background: none;
    /* width: var(--width_45); */
    padding: 0;
}

.cancel_button img {
    max-width: 20px;
}
