.confirm_logOut_container {
    gap: 20px;
  }
  
  .confirm_logOut_container button {
    width: 140px;
    font-size: 14px;
    height: 40px;
    border-radius: var(--sm);
  }
  
  .confirm_logOut {
    gap: 30px;
  }
  
  .logOut_text_container {
    max-width: 350px;
  }
  