.data_sort_container {
  border-radius: var(--md);
  width: 300px;
  -webkit-box-shadow: 6px 6px 13px 0px rgba(118, 118, 118, 25%);
  box-shadow: 6px 6px 13px 0px rgba(118, 118, 118, 25%);
  z-index: 2;
  background: var(--white);
}

.sort_data {
  z-index: 1;
  padding: 0 10px 10px 10px;
  gap: 5px;

}

.sort_data span {
  font-weight: var(--fontWeight_400);
  font-size: var(--smFontSize);
  color: var(--black);
}

.sorting_title {
  gap: var(--gap_lg);
  padding: 10px;
}

.sort_data span:hover {
  color: var(--mainColor);
  cursor: pointer;
}

.animation {
  transition: all 500ms;
  transition-timing-function: ease-in-out;
  animation: sliderSideImageAnimation 1s ease-out forwards;
}

.animation_container {
  animation: animation_container 1s ease-out forwards;
}

.animation_hidden {
  animation: animation_hidden 1s ease-out forwards;
}

.sort_item_data {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
}

.sorting_title:hover {
  cursor: pointer;
}

@keyframes animation_hidden {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(-5%);
  }
}

@keyframes animation_container {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(5%);
  }
}

@keyframes sliderSideImageAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  .data_sort_container {
    position: relative !important;
  }

  .sort_data {
    position: absolute !important;
    z-index: 10;
    background: var(--white);
    border-radius: var(--md);
    top: 40px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 6px 10px 4px 5px rgba(116, 116, 116, 0.2)
  }
}

@media screen and (max-width: 380px) {
  .data_sort_container {
    max-width: 300px;
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .data_sort_container {
    max-width: 220px;
    width: 100%;
  }
}
