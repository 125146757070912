  .play_back_container {
    padding: 5px 0px;
    width: 27%;
    background-color: rgba(0, 0, 0, 0.5);
    right: 1.2%;
    bottom: 47px;
    border-radius: 10px;
    max-width: 250px;
    min-width: 200px;
    max-height: 70%;
  }

  .play_back_container p {
    margin-bottom: 2px;
    font-weight: 600;
    padding: 0 10px;
  }

  .play_back_container {
    margin-right: 10px;
  }
  
  .play_back {
    border-bottom: 1px solid var(--white);
    margin-bottom: 5px;
    padding-left: 10px;
    padding-bottom: 2px;
    min-height: 30px;
  }

  .play_back p {
    padding: 0 10px 0 5px;
    line-height: 14px;
  }
  .fill_white {
      filter: brightness(0) invert(1);
  }
  .cursor_pointer {
    cursor: pointer;
  }

  .back {
    width: 7px;
    height: 10px;
  }