.filter_container {
    filter: drop-shadow(10px 10px 4px rgba(118, 118, 118, 0.17));
    background: var(--white);
    gap: var(--gap_lg);
    top: 10px;
    border-radius: var(--sm);
    height: calc(100vh - 250px);
    width: 260px;
    transition: none;
    min-width: 260px;
}

.data_container {
    padding: 20px 10px;
    gap: 10px;
}

.clear_container {
    padding: 10px;
}

.clear_container button {
    font-weight: var(--fontWeight_400);
    color: var(--black);
    font-size: var(--smFontSize);
}

@media screen and (max-width:981px) {
    .filter {
        position: fixed;
        display: flex;
        top: 95px;
        left: 0;
        z-index: 5000;
        filter: drop-shadow(10px -1px 4px rgba(118, 118, 118, 0.17));
        background: var(--white);
        height: calc(100vh - 250px);
        gap: var(--gap_lg);
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border-radius: var(--sm);
        width: 0px;
        transition: none;
        z-index: 100000;
        height: calc(100vh - 95px);
    }

    .w_250 {
        width: 250px;
        transition: width 700ms ease-in-out;
    }

    .width_0 {
        width: 0px;
        transition: width 700ms ease-in-out;
    }

}