  .video_container {
    background: var(--black);
    border-radius: 8px;
  }

  .video_container video {
    border-radius: 8px;
  }

  .video {
    max-height: 490px;
  }

  .video::-webkit-media-controls-play-button {
      display: none !important;
  }

  .video::-webkit-media-controls-volume-slider {
      display: none !important;
  }

  .video::-webkit-media-controls-mute-button {
      display: none !important;
  }

  .video::-webkit-media-controls-timeline {
      display: none !important;
  }

  .video::-webkit-media-controls-current-time-display {
      display: none !important;
  }

  .video::-webkit-media-controls {
    display: none !important;
  }

  .video:hover {
    cursor: pointer;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1.5s, opacity 1.5s linear;
  }

  .margin_right_auto {
    margin-right: auto;
  }

  .modal {
    max-height: unset;
  }

  .center_menu_container img {
  filter: brightness(0) invert(1);
}
  .center_menu_container {
    z-index: 10000;
  }

  .next_or_prev {
    width: 50%;
  }
  