.bg_no_data {
    height: 52vh;
    width: 65%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.no_data_text {
    color: var(--mainColor);
    font-weight: 600;
    font-size: 45px;
    padding-bottom: 30px;
}

@media screen and (max-width: 1600px) {
    .bg_no_data {
        height: 50vh;
        width: 75%;
    }
}

@media screen and (max-width: 1200px) {
    .bg_no_data {
        height: 48vh;
        width: 80%;
    }
}

@media screen and (max-width: 820px) {
    .bg_no_data {
        height: 40vh;
        width: 90%;
        min-width: 300px;
    }

    .no_data_text {
        font-size: 40px;
    }
}