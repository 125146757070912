.title_data {
    font-weight: var(--fontWeight_600);
    font-size: var(--smFontSize);
}
.title {
    color: var(--black);
}
.light_grey_title {
    color:var(--inputTextColor)
}


@media screen and (max-width:545px) {
    .title_data {
        text-overflow: ellipsis;
        display: flex;
        max-width: 200px;
    }

    .title_data_container {
        overflow: hidden;
        width: 80%;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width:455px) {
    .title_data {
        max-width: 150px;
    }

    .title_data_container {
        width: 60%;
    }
}

@media screen and (max-width:350px) {
    .title_data {
        max-width: 100px;
    }

    .title_data_container {
        width: 50%;
    }
}

@media screen and (max-width:335px) {
    .title_data {
        font-size: 14px;
    }
}
@media screen and (max-width:315px) {
    .title_data {
        font-size: 12px;
        max-width: 80px;
    }

    .title_data_container {
        width: 30%;
    }
}