.my_books_tab_item_container {
    gap: 20px;
}

@media screen and (max-width:430px) {
    .my_books_tab_item_container {
        justify-content: center;
    }
}

