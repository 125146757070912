.action_icon_container {
    width: var(--width_30);
    background: none;
}

.action_icon_container img {
    width: 100%;
}

.language_dropdown_visible {
    border-radius: 0px 0px 12px 12px;
    width: var(--xlWidth);
    background-color: var(--white);
    border: 1px solid var(--white)
}

.language_dropdown_visible img {
    width: 100%;
}

.language_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 10px;
    gap: 5px;
}

.language_container button {
    border: none;
    background: none;
    padding: 0;
    z-index: 3;
    max-width: 40px;
    width: 90%;
}

.languages_container {
    display: flex;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.language_data {
    overflow: hidden;
    height: 0px;
    top: 100px;
    background: none;
    transition: all 500ms;
    transition-timing-function: ease-in-out;
    position: absolute;
    z-index: 10;
    border-radius: 0 0 12px 12px;
    -webkit-box-shadow: 0px 4px 13px 2px rgb(9 188 193 / 25%);
    box-shadow: 0px 4px 13px 2px rgb(9 188 193 / 25%);
}
.language_data_animated {
    height: 200px;
}

@media screen and (max-width:820px) {
    .language_dropdown_visible {
        width: 70px;
    }
    .language_dropdown_visible img{
        width: 40px;
    }
}