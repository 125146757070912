.gap_10 {
    gap: 10px;
}

.my_books_card_item_container {
    max-width: 390px;
    height: 230px;
    border-radius: 8px;
    padding: 10px;
    gap: 20px;
    box-shadow: 6px 6px 4px 5px rgba(116, 116, 116, 0.1);
}

.my_books_card_item_container:hover {
    cursor: pointer;
}

.img_data_container {
    width: 120px;
    height: 200px;
}

.img_data_container img {
    border-radius: 8px;
}

.delete_container {
    max-height: 40px;
}

.delete_container span:hover {
    cursor: pointer;
}

.delete_container button {
    background: none;
    padding: 0;
    color: var(--mainColor);
}

.card_data_container {
    max-height: 160px;
    max-width: 230px;
}

.background {
    z-index: 10;
}

.max_width_220 {
    max-width: 220px;
}

@media screen and (max-width:430px) {
    .my_books_card_item_container {
        flex-wrap: wrap;
        max-width: 250px;
        height: 400px;
    }

    .maxWidth_220 {
        max-width: 230px;
    }

    .my_books_data_container {
        justify-content: center;
        width: 100%;
    }

    .img_data_container {
        max-width: 160px;
        width: 100%;
    }

    .card_data_container {
        padding: 0 20px;
    }
}