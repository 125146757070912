.user_data_item_container {
    height: var(--height_50);
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
    background: var(--white);
    padding: 10px 20px;
    gap:10px;
    border-radius: 4px;
}
.user_data_item_container:hover {
   cursor: pointer;
}

.title {
    color: var(--black);
}

.data_container {
    gap: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

@media screen and (max-width:826px) {
    .data_container {
        gap: 30px;
    }
}
@media screen and (max-width:400px) {
    .title_age {
        overflow-x: scroll !important;
    }
}
@media screen and (max-width:335px) {
    .data_container {
        gap: 30px;
    }
}


