.contacts_card {
    max-width: var(--maxwidth_350);
    border-radius: var(--xl);
    height: 200px;
}

.width_60 {
    width: var(--width_60);
}

.card_item_data {
    gap: var(--gap_md);
}

.card_item_data span {
    font-size: var(--lgFontSize);
}

.card_item_data a {
    font-size: var(--lgFontSize);
    color: var(--black);
}

.card_item_data a:hover {
    color: var(--mainColor);
}

.card_item_data_container {
    gap: var(--gap_md);
    padding: 0 30px;
}

.adress_text {
    font-weight: var(--fontWeight_300);
}

.adress_text_data {
    font-weight: var(--fontWeight_500);
}