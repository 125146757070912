.slider {
  height: 350px;
  gap: 10px;
  margin-top: 80px;
  padding: 10px 20px;
}

.carousel_container {
  max-width: 700px;
  border-radius: var(--radus_3xl);
  background-color: #93cbff;
  padding: 10px;
  min-height: 370px;
}

.slider_side_image {
  max-width: 220px;
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.slider_side_image img {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  overflow: hidden; 
  height: 100%; 
  width: 100%;
}

.slider_side_image_animation {
  -webkit-animation: slider_side_image_animation 1.5s forwards;
  -moz-animation: slider_side_image_animation 1.5s forwards;
  animation: slider_side_image_animation 1.5s forwards;
}

@keyframes slider_side_image_animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1090px) {
  .slider_side_image {
    display: none;
  }
}

@media screen and (max-width: 515px) {
  .slider {
    margin-top: 110px;
  }
}
