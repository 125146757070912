.slide_title {
  margin-top: 10px;
  width: 45%;
  max-width: none;
  gap: 10px;
  position: absolute;
  color: var(--white);
}

.slide_title h1, h3, p {
  color: var(--white);
}

.slide_title h1 {
  font-size: 25px;
  font-weight: 600;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 100%;
  text-align: left;
}
.slide_title h3 {
  font-size: 18px;
  text-align: left;
  font-weight: 600;
}

.slide_title p {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1180px) {
  .slide_title h1 {
    font-size: 22px;
  }
}
  @media screen and (max-width: 685px) {
  .slide_title {
    max-width: 250px;
    width: 100%;
  }
}

@media screen and (max-width: 675px) {
  .slide_title {
    max-width: 200px;
  }
  .slide_title h3 {
    font-size: 18px;
    text-align: center;
  }
  .slide_title p {
    display: none;
  }
}

@media screen and (max-width: 675px) {
  .slide_title {
    position: static;
    width: 100%;
    max-width: none;
    align-items: center;
  }
  .slide_title h1,
  h3 {
    text-align: center;
  }
}
