.carousel_image_container {
  max-height: 400px;
  width: 53%;
  max-width: 230px;
  position: absolute;
  bottom: 60px;
  border-radius: 8px;
  left: 30px;
  z-index: 2;
}

.carousel_image_container:hover {
  cursor: pointer;
}

.slide_image {
  z-index: 2;
}

.slide_image:hover {
  cursor: pointer;
}

@media screen and (max-width: 685px) {
  .carousel_image_container {
    max-width: 220px;
    left: 10px;
    bottom: 70px;
  }
}

@media screen and (max-width: 675px) {
  .carousel_image_container {
    max-width: 190px;
    bottom: 50px;
  }

  .carousel_image_container {
    position: static;
    width: auto;
  }
}