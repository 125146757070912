.text_settings_container {
    padding: 0 10px;
    height: 40px;
    border-bottom: 1px solid #cfd9dc;
}

.text_setting:hover {
    cursor: pointer;
}

.border_right {
    border-right: 1px solid #cfd9dc;
}

.height_40 {
    height: 40px;
}

.enlarge_text {
    font-size: 18px;
    font-weight: 550;
}

.smaller_text {
    font-size: 15px;
    font-weight: 500;
}