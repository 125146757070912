.gap_5 {
    gap: 5px;
}

.gap_10 {
    gap: 10px;
}

.episodes_container span {
    font-size: 16px;
    color: var(--black);
}

.font_weight_600 {
   font-weight: var(--fontWeight_600);
}

.episod_container {
    border: 1px solid var(--mainColor);
    border-radius: 8px;
    height: 95px;
    min-width: 170px;
    gap: 10px;
}

.title {
    font-weight: var(--fontWeight_400);
    font-size: var(--smFontSize);
}

.font_weight_400 {
    font-weight: var(--fontWeight_400);
}

.episode_item {
    height: 120px;
    gap: 25px;
}

.active_item {
    background: rgba(17, 153, 157, 0.2);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 25px;
}

.active_item span {
    font-size: 16px;
    font-weight: 100;
    font-style: italic;
    color: var(--mainColor);
}

.pading_5_10 {
    padding: 5px 10px;
}

.episod_container:hover {
    cursor: pointer;
}

.time_episode_container {
    font-size: 15px !important;
}

/* .padding_left_15 {
    padding-left: 15px;
} */