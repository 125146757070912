.gap_20 {
  gap: 20px;
}
.item_container {
  max-width: var(--maxwidth_150);
  gap: var(--gap_md);
}

.footer_menus_link {
  color: var(--black);
  font-size: 14px;
}

.undecorated_link:hover {
  text-decoration: none;
  color: var(--mainColor);
}

.footer_menus_title {
  font-size: 16px;
  font-weight: var(--fontWeight_600);
  color: var(--black);
}

.block_background {
  background: var(--mainItemColor);
}

.data_container {
  max-width: var(--xlMaxWidth);
  padding: 20px;
}

.main_content {
  max-width: 1000px;
}

.company_information {
  height: 100px;
  padding: 10px 30px;
  align-items: center;
}

@media screen and (max-width: 670px) {
    .item_container {
        max-width: 45%;
    }
}
@media screen and (max-width: 510px) {
  .company_information {
    height: 120px;
  }
}
@media screen and (max-width: 500px) {
  .company_information {
        flex-wrap: wrap;
        gap: 10px
    }
    .company_information:last-child {
      justify-content: center !important;
    }
  }

@media screen and (max-width: 350px) {
    .item_container {
        width: 100%;
        max-width: none;
    }
    .company_information  {
      padding: 0;
    }
}