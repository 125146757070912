.dropdown_container {
    width: 230px;
    top: 25px;
    transition: all 500ms;
    transition-timing-function: ease-in-out;
    height: 0;
    background: var(--white);
    box-shadow: 0px 3px 4px rgba(133, 224, 226, 0.5);
    border-radius: 5px;
    z-index: 4000;
}
.dropdown_container span {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
}

.height_60 {
    height: 60px;
}